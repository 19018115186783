import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
/*
import Google from "./images/Google.svg";
import Apple from "./images/Apple.svg";
import Github from "./images/Github.svg";
import Windows from "./images/Windows.svg";
import Facebook from "./images/Facebook.svg";
import DividerLine from "./images/divider-line.svg";
*/
import BackBtn from "./images/back-btn.svg";
import toast, { Toaster } from "react-hot-toast";
import { motion } from "framer-motion";
import { apiRequestPwdReset } from './ApiClient';


function ConfirmAccount ({ navigateViaParent }) 
{
  const [email, setEmail] = useState("");
  const topLevelNavigation = useNavigate();

  const handleEmailInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async () => {
    //Implement defensive conditional method to check for valid response
    const post_data = { email: email };
    apiRequestPwdReset(post_data, function(data) {
        toast.success('Password Reset Request Submitted Successful!');
        topLevelNavigation ("/");
    });
  };

  return (
    <motion.div
    className="box"
    initial={{ opacity: 0, scale: 0.95 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{
      duration: .5,
      ease: "easeOut",
      scale: {
        type: "spring",
        damping: 10,
        stiffness: 50,
        restDelta: 0.001
      }
    }}
  >
    <div className="login-container">
      <Toaster />
      <div className="welcome-wrapper d-flex flex-row justify-content-center align-items-center">
        <img
          className="clickable back-arrow-btn"
          loading="lazy"
          alt="back-btn"
          src={BackBtn}
          onClick={() => navigateViaParent("Login")}
        />
        <h1 className="d-flex justify-content-center pricing-title pt-3 py-3">
          Confirm Account
        </h1>
      </div>

      <section className="frame-parent mb-2">
        <div className="frame-group">
          <div className="input-wrapper">
            <label htmlFor="emailInput" className="form-label input-label-text">
              Email
            </label>
            <input
              type="text"
              id="emailInput"
              name="email"
              className="input-field-login p-3"
              value={email}
              onChange={handleEmailInputChange}
              placeholder="Enter your Email Address"
            />
          </div>
        </div>

        <div className="group-frame d-flex justify-content-center flex-row">
          <div className="rectangle-parent">
            <div className="frame-inner" />
            <button className="sign-up render-btn px-5 py-2" onClick={() => navigateViaParent("Login")}>Cancel</button>
          </div>
          <div className="rectangle-group">
            <div className="rectangle-div" />
            <button className="login1 confirm-btn px-5 py-2 mb-2" onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      </section>
    </div>
    </motion.div>
  );
};

export default ConfirmAccount;
