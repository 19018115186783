import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { apiGetAllAssets, apiDeleteAsset, apiGetDashboardData } from './ApiClient';
import { authTokenBuildUrl } from './AuthToken.js';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "../App.css";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { motion } from "framer-motion";
import { phpSessIsLoggedIn, phpSessAssetCountSet } from './PhpSession.js';
import DividerLine from "./images/divider-line.svg";
import TrashCan from "./images/trash-can.png";
import CopyCode from "./images/copy-code.png";
//import AddAsset from "./images/add-asset.svg";
import TemplateSelector from './TemplateSelector';

function DashboardManagerConcept ({ forceRedrawCallback })
{
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const [openRefresh, setOpenRefresh] = useState(false);
  const [currentAssetName, setCurrentAssetName] = useState("");
  const [currentAssetId, setCurrentAssetId] = useState(null);
  const [password, setPassword] = useState("");
  const [allData, setAllData] = useState([]);
  const [assetValue, setAssetValue] = useState(25);  // Set initial value to 25
  const [tempValue, setTempValue] = useState(assetValue); // Temp value for slider during dragging
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100);
  const [imgUrl, setImgUrl] = useState("");  // Updated to store only one image URL
  const [urlUpdated, setUrlUpdated] = useState(false);  // State to handle URL update animation
  const [openNewAssetModal, setOpenNewAssetModal] = useState(false);
//const [leftImgUrl, setLeftImgUrl] = useState("");  // New state for the left image
//const [rightImgUrl, setRightImgUrl] = useState("");  // New state for the right image
//const [assetName, setAssetName] = useState("");
//const [selectedComponentId, setSelectedComponentId] = useState("");
//const [activeButton, setActiveButton] = useState(null);
//const [sessionToken, setSessionToken] = useState("");
//const [conceptState, setConceptState] = useState(0);

//const imageRef = useRef(null);

  useEffect(() => {
    //setSessionToken(phpSessGet());
    if (phpSessIsLoggedIn ()) {
      apiGetDashboardData (function (data) {
        if (data && data.assets) {
          setAllData (data.assets);
          phpSessAssetCountSet (data.assets.length);
          forceRedrawCallback ();
        } 
        else {
          toast.error('Failed to login. Please check your credentials.');
        }
      });
    } 
    else {
      setAllData([]);
      phpSessAssetCountSet (0);
      forceRedrawCallback ();
    }
  }, []);

  const handleCancelDelete = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setOpenDelete(false);
  };

  const handleCancelRefresh = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setAssetValue(25); // Reset the asset value to 25 when closing the modal
    setTempValue(25);  // Reset tempValue as well
    setOpenRefresh(false);
  };

  const handleConfirmDelete = () => {
    if (!password) {
      toast.error("Please enter your password.");
      return;
    }
    apiDeleteAsset(currentAssetId, password, (response) => {
      toast.success (`${currentAssetName} has been deleted.`);
      setOpenDelete(false);
      setPassword("");
      apiGetAllAssets ((data) => {
        setAllData (data);
        phpSessAssetCountSet (data.length);
        forceRedrawCallback ();
      });
    });
  };

  const handleSliderChange = (event) => {
    const newValue = Math.round(parseInt(event.target.value, 10) / 10) * 10; // Round to the nearest multiple of 10
    setTempValue(newValue);  // Update temporary value on slider drag
  };

  const handleSliderMouseUp = () => {
    setAssetValue(tempValue); // Commit the temporary value when the mouse is released
    buildFullUrl (tempValue); // Trigger the URL build with the new value
  };

  function handleInputChange (event)
  {
    let newValue = parseInt(event.target.value, 10);
    if (isNaN(newValue)) {
      //newValue = minValue; // Handle non-numeric input
      return;
    }
    newValue = Math.max(minValue, Math.min(maxValue, newValue)); // Ensure the value stays within range
    setAssetValue(newValue);
    setTempValue(newValue); // Update the temp value to keep the slider in sync
    buildFullUrl (newValue);
  }

  function handleIncrement ()
  {
    if (tempValue >= maxValue)
      return;
    const newValue = Math.min(tempValue + 10, maxValue);
    setTempValue (newValue);
    buildFullUrl (newValue);
  }

  function handleDecrement ()
  {
    if (tempValue >= minValue)
      return;
    const newValue = Math.min(tempValue - 10, minValue);
    setTempValue (newValue);
    buildFullUrl (newValue);
  }

  const onOpenRefreshModal = (assetId, assetName, event) => {
    event.stopPropagation();
    setCurrentAssetName(assetName);
    setCurrentAssetId(assetId);
    setAssetValue(25); // Reset assetValue to 25 each time the modal opens
    setTempValue(25);  // Reset tempValue to 25 as well
    setOpenRefresh(true);  // This will trigger the useEffect to run buildFullUrl (25)
};

useEffect(() => {
  if (openRefresh) {
    buildFullUrl (assetValue);
  }
}, [openRefresh]);

  const onOpenDeleteModal = (assetId, assetName, event) => {
    event.stopPropagation();
    setCurrentAssetName(assetName);
    setCurrentAssetId(assetId);
    setOpenDelete(true);
  };

  const openEditor = (component_name, asset_id, asset_name, event) => {
    if (openDelete || openRefresh) {
      event.stopPropagation();
      return;
    }

    var editorName = "radialbasic";
    switch (component_name) {
      default:
        return;

      case "seg_bar16":
      case "seg_bar32":
      case "seg_bar64":
        editorName = "bar";
        break;
      case "arrow1":
        editorName = "arrow";
        break;
      case "wedges1":
        editorName = "radialbasic";
        break;
    }
    navigate(`/editor/${editorName}/${asset_id}`, { state: { assetName: asset_name } });
  };

  const handleimgUrlCopyClick = () => {
    navigator.clipboard.writeText(imgUrl);
    toast.success("✓ Image URL has been copied to clipboard!", {
      position: "top-center",
      duration: 5000,
      style: {
        background: '#4caf50',
        color: '#fff',
        fontSize: '16px',
      },
    });
  };

  const onOpenNewAssetModal = () => {
    setOpenNewAssetModal (true);
  };

  const onCloseNewAssetModal = () => {
    setOpenNewAssetModal(false);
  };
  
  const buildFullUrl = (value) => {
    authTokenBuildUrl (currentAssetName, value, function (fullUrl) {
      setImgUrl (fullUrl);
      setUrlUpdated(true);  // Trigger the animation
      setTimeout(() => setUrlUpdated(false), 1000);  // Reset the animation state after 1 second
    });

    //authTokenBuildUrl (currentAssetName, value - 10, function (fullUrl) {
      //setLeftImgUrl (fullUrl);
    //});

    //authTokenBuildUrl (currentAssetName, value + 10, function (fullUrl) {
      //setRightImgUrl (fullUrl);
    //});
  };

/*const handleDownload = () => {
    if (imageRef.current === null) {
      return;
    }

    toPng(imageRef.current)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'image.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error('oops, something went wrong!', err);
      });
  }; */

  return (
    <div className="dashboard-manager-main container-fluid dashboard-bg">
      <motion.div
        className="box"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 1.5,
          ease: "easeOut",
          scale: {
            type: "spring",
            damping: 10,
            stiffness: 50,
            restDelta: 0.001
          }
        }}
      >
        <div className="d-flex flex-wrap justify-content-around align-items-center add-new-asset-panel mb-2 px-4" onClick={onOpenNewAssetModal}>
          <div className="d-flex align-items-center">
            <div className="component-header">+Create New Asset</div>
          </div>
        </div>

        {allData.map(item => (
          <div
            key={item.asset_id}
            className="d-flex justify-content-between align-items-center box-shadow messy-styling bg-box-component px-lg-5 py-3 px-2 gap-3 mb-2 clickable"
            onClick={(e) => openEditor(item.component_name, item.asset_id, item.asset_name, e)}
            style={{ position: 'relative' }}
          >
            <div className="d-flex align-items-center gap-3">
              <img
                className="flex-shrink-0"
                src={item.image}
                alt=""
                style={{ height: 'auto', width: '40px', objectFit: 'cover' }}
              />
              <div className="d-flex flex-column align-items-start">
                <h3 className="m-0 d-inline-block component-title pl-2">
                  {item.asset_name}
                </h3>
              </div>
            </div>

            <div className="d-flex align-items-center gap-3">
              <button
                className="update-asset-btn api-key mr-5"
                onClick={(e) => onOpenRefreshModal(item.asset_id, item.asset_name, e)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: 'none',
                  cursor: 'pointer',
                }}
                aria-label="Update Asset"
              >
                Asset Preview
              </button>

              <Modal
                open={openRefresh}
                onClose={handleCancelRefresh}
                center
                showCloseIcon={false}
                styles={{
                  modal: {
                    padding: "10px 20px 10px 20px",
//                  background: "rgb(25,25,34)",
                    background:
                      "radial-gradient(circle, rgba(25,25,34,1) 0%, rgba(21,21,28,1) 100%)",
                    borderRadius: "10px",
                  },
                  overlay: {
                    background: "rgba(0, 0, 0, 0.1)"
                  },
                }}
              >
                <div className="d-flex flex-column" style={{ overflow: "hidden", maxWidth: "100%" }}>
                  <div className="col-12 p-0">
                    <p className="toolbar-header-concept gradient-toolbar-concept mt-1 py-3 pl-3">
                      Dynamic URL Builder
                    </p>
                    <div className="">
                      <div className="d-flex justify-content-center align-items-center">
                        <input
                          type="number"
                          value={assetValue}
                          onChange={handleInputChange}
                          step={10} // Set the step attribute to 10
                          className="form-control text-center"
                          style={{ maxWidth: '80px', marginRight: '20px' }}
                        />
                        <button className="increment-btn" onClick={handleDecrement}>
                          &#9664;
                        </button>
                        <div className="mx-2 toolbar-header-concept">{minValue}</div>
                        <input
                          type="range"
                          min={minValue}
                          max={maxValue}
                          value={tempValue}
                          step={10} // Set the step attribute to 10
                          onChange={handleSliderChange}
                          onMouseUp={handleSliderMouseUp}
                          className="slider mx-2"
                          style={{ flex: 1 }}
                        />

                        <div className="mx-2 toolbar-header-concept">{maxValue}</div>
                        <button className="increment-btn" onClick={handleIncrement}>
                          &#9654;
                        </button>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mt-3 mb-3 preview-box">
                      
                      <img
                        src={imgUrl}
                        alt="Generated thumbnail"
                        style={{ width: 'auto', height: '200px', objectFit: 'cover' }}
                        //ref={imageRef}
                      />
                      
                    </div>

                    <div className="">
                      <p className="toolbar-header-concept mb-0 mt-2 pl-3">
                        Image URL
                      </p>

                      <div className={`d-flex align-items-center mt-0 mb-2 mx-2 url-container ${urlUpdated ? 'fade-border' : ''}`}>
                        <div className="mx-2 url-container api-key-text">{imgUrl}</div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-around">
                    <button
                      className="btn cancel-btn"
                      onClick={handleCancelRefresh}
                      style={{ height: '50px', width: '200px' }}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn copy-url-btn"
                      onClick={handleimgUrlCopyClick}
                      style={{ height: '50px', width: '200px' }}
                    >
                      <img
                        src={CopyCode}
                        className="copy-code-icon pr-2"
                        alt="Copy"
                      />
                      COPY URL
                    </button>
                  </div>
                </div>
              </Modal>

              <img
                className="trashcan-icon flex-shrink-0 ml-2"
                src={TrashCan}
                loading="lazy"
                alt="Delete"
                onClick={(e) => onOpenDeleteModal(item.asset_id, item.asset_name, e)}
                style={{ cursor: "pointer" }}
              />

              <Modal
                open={openDelete}
                onClose={handleCancelDelete}
                center
                showCloseIcon={false}
                styles={{
                  modal: {
//                  background: "rgb(18,17,19)",
                    background:
                      "linear-gradient(45deg, rgba(18,17,19,1) 0%, rgba(37,35,40,1) 38%, rgba(18,19,20,1) 100%)",
                    borderRadius: "5px",
                  },
                  overlay: {
                    background: "rgba(0, 0, 0, 0.1)"
                  },
                }}
              >
                <div className="d-flex flex-column justify-content-center p-2">
                  <p className="form-label doc-body-large mb-3">Are you sure you want to delete: "{currentAssetName}"?</p>
                  <img
                    src={DividerLine}
                    alt="Browser setup"
                    style={{ width: "100%", height: "auto" }}
                    className="mb-2"
                  />
                  <p className="form-label doc-body-medium mb-2 mt-2">Enter your Account password:</p>
                  <div>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => {
                        e.stopPropagation();
                        setPassword(e.target.value);
                      }}
                      onClick={(e) => e.stopPropagation()}
                      placeholder="Enter password"
                      className="input-field-asset-name p-3 mb-3"
                    />
                  </div>
                  <p className="form-label doc-body-medium mb-2">Then click confirm to delete this asset.</p>
                  <div className="d-flex justify-content-around mt-3">
                    <button
                      className="btn cancel-btn"
                      onClick={handleCancelDelete}
                      style={{ height: '50px', width: '150px' }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn confirm-btn"
                      onClick={handleConfirmDelete}
                      style={{ height: '50px', width: '150px' }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        ))}

        <Modal
          open={openNewAssetModal}
          onClose={onCloseNewAssetModal}
          className="login-container"
          center
          showCloseIcon={false}
          styles={{
            modal: {
              background: "rgb(25,25,34)",
              background: "radial-gradient(circle, rgba(25,25,34,1) 0%, rgba(21,21,28,1) 100%)",
              borderRadius: "10px",
            },
          }}
        >
          <TemplateSelector forceRedrawCallback={forceRedrawCallback} closeModal={onCloseNewAssetModal} />
        </Modal>
      </motion.div>
    </div>
  );
};

export default DashboardManagerConcept;
