import React from "react";
import Sidebar from "../Sidebar";
import "../../../App.css";
import CodeSnippet from "../../Prism";
import BrowserSetup from "../../images/tutorial-browser-setup.png";
import ConsoleSetup from "../../images/tutorial-concept-setup.png";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-jsx";

const WaysToUse = () => {

  return (
    <div className="bg-main text-white mt-5">
      <div className="row">
        <Sidebar />
        <div className="col doc-container">
          <div className="col mt-4">
            <h2 className="doc-caps">How to use the MD99 API</h2>
            <h1 className="doc-header">MD99 Dynamic Group</h1>
            <h3 className="doc-body-large">Introducing MD99: Unleashing Power and Flexibility in Dynamic Server-Side Rendering</h3>
            <p className="doc-sub-header">Discover the unparalleled power and flexibility of MD99, your ultimate tool for dynamic server-side rendering. Designed for professional developers, MD99 provides the robust capabilities needed to handle data reporting at all levels—from individual and team projects to large-scale institutional and organizational needs.</p>
            <p className="doc-sub-header">With MD99, you're equipped to take your data visualization to the next level. Our platform supports dynamic route construction, enabling you to create tailored, real-time data graphics that meet your specific requirements. Whether you're visualizing performance metrics for a small team or presenting comprehensive reports for an entire organization, MD99 ensures your data is clear, compelling, and actionable.</p>
            <p className="doc-sub-header">To get you started, our Dynamic Route Construction tutorial offers GitHub repository templates that you can clone and test. These templates serve as a foundation, allowing you to refactor, review, and seamlessly incorporate them into your own projects. This approach not only accelerates your development process but also fosters creative problem-solving, empowering you to push the boundaries of what's possible with data visualization.</p>
            <p className="doc-sub-header">We believe in the power of collaboration and continuous improvement. That's why we encourage our users to provide feedback and share their projects with us on social media. Your insights help us refine our platform, ensuring that MD99 remains the go-to solution for dynamic data rendering.
            </p>
            <p className="doc-sub-header">Join the MD99 community today and harness the full potential of our platform. Experience the ease of creating sophisticated data reports and visuals that scale effortlessly with your needs. With MD99, your data presentations are not just enhanced—they're transformed.
            </p>
            <p className="doc-sub-header">Embrace the future of data reporting with MD99. Start exploring our tutorials, leverage our templates, and see how our powerful tools can elevate your projects. Share your journey with us and become part of a thriving community of innovative developers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaysToUse;
