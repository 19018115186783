import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { apiGetOneAsset, apiSaveOneAsset, apiUpdateAssetName } from "../Components/ApiClient";
import toast from "react-hot-toast";
import { motion } from "framer-motion";
import { phpSessGet, phpSessSet, phpSessLogout, phpSessIsLoggedIn } from '../Components/PhpSession.js';
import BackBtn from "../Components/images/back-btn.svg";
import { useNavigate } from "react-router-dom";
import Edit from "../Components/images/edit.png";
import FontFamily from "../EditUtils/FontFamily.js";
import ColorPopover from '../EditUtils/ColorPopover.js';
import DelayedInput from '../EditUtils/DelayedInput.js';

const Arrow = () => {
  const all_params = useParams();
  const isMounted = useRef(false);
  const [assetInputName, setAssetInputName] = useState("");
  const location = useLocation();
  const { assetName, sessID } = location.state || {};
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [size, setSize] = useState("");
  const [initialSize, setInitialSize] = useState("");
  const [cutOffValue, setCutOffValue] = useState("");
  const [arrowUpColor, setArrowUpColor] = useState("#3d3d42");
  const [arrowDownColor, setArrowDownColor] = useState("#3d3d42");
  const [shadowColor, setShadowColor] = useState("#29292D");
  const [fontFamily, setFontFamily] = useState("bebasneue-regular");
  const [fontColor, setFontColor] = useState("#FFFFFF");
  const [dynamicImgUrl, setDynamicImgUrl] = useState("");
  const [arrowData, setArrowData] = useState({});
  const [ranges, setRanges] = useState({});
  const [backgroundStyle, setBackgroundStyle] = useState({
    backgroundColor: "white",
    backgroundImage: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingSize, setIsEditingSize] = useState(false);
  //const [displayColorPicker, setDisplayColorPicker] = useState({
    //arrowUp: false,
    //arrowDown: false,
    //font: false,
    //shadow: false,
  //});


  const handleEditClickSize = () => {
    setIsEditingSize(true);
    setInitialSize(size);
  };

  const handleCancelClickSize = () => {
    setIsEditingSize(false);
    setSize(initialSize);
  };

  const handleConfirmClickSize = () => {
    const parsedSize = parseInt(size, 10);
    if (isNaN(parsedSize) || parsedSize <= 25) {
      toast.error('Please enter a valid integer greater than 25');
      return;
    }

    //toast.success('Size Updated!');
    setIsEditingSize(false);
    newRenderComponentClick();
  };

  const retrieveUserLastDesign = () => {
    apiGetOneAsset(all_params.asset_id, function (data) {
        const params = JSON.parse(data.params);

        setDynamicImgUrl(data.image);
        setAssetInputName(data.asset_name); // Set the updated asset name here
        setSize(params.full_w);
        setInitialSize(params.full_w);
        setArrowUpColor(params.up_c);
        setArrowDownColor(params.down_c);
        setFontColor(params.font_c);
        setFontFamily(params.font_fam);
        setCutOffValue(params.cutoff_v);
        setRanges(params.ranges);
    });
};


  useEffect(() => {
    if (sessID) {
      phpSessSet(sessID);
    }
    setAssetInputName(assetName);
    retrieveUserLastDesign();
  }, [sessID, assetName]);

  useEffect(() => {
    if (isMounted.current) {
      newRenderComponentClick();
    } else {
      isMounted.current = true;
    }
  }, [arrowUpColor, arrowDownColor, fontColor, fontFamily, cutOffValue]);

  const newRenderComponentClick = () => {
    const post_data = {
      asset_id: all_params.asset_id,
      full_w: size,
      up_c: arrowUpColor,
      down_c: arrowDownColor,
      font_c: fontColor,
      font_fam: fontFamily,
      cutoff_v: cutOffValue,
    };

    apiSaveOneAsset(post_data, function (data) {
      setDynamicImgUrl(data.image);
    });
  };

  const handleColorChange = (colorSetter, color) => {
    colorSetter(color);
  };

  const handleInputChange = (event) => {
    setSize(event.target.value);
  };

  const handleAssetInputNameChange = (event) => {
    setAssetInputName(event.target.value);
  };


  //useEffect(() => {
  //}, [])


  const handleWhiteBackground = () => {
    setBackgroundStyle({
      backgroundColor: "white",
      backgroundImage: "",
    });
  };

  const handleBlackBackground = () => {
    setBackgroundStyle({
      backgroundColor: "black",
      backgroundImage: "",
    });
  };

  const handleShadowBackground = () => {
    setBackgroundStyle({
      backgroundColor: shadowColor,
      backgroundImage: "",
    });
  };

  useEffect(() => {
    handleShadowBackground();
  }, [shadowColor]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setAssetInputName(assetName);
  };

  const handleConfirmClick = () => {
    apiUpdateAssetName(all_params.asset_id, assetInputName, (data) => {
      if (data.is_success === "1") {
          toast.success('Asset Name Updated!');
          setAssetInputName(assetInputName); // Update state with the new name
          setIsEditing(false);
      } else {
          toast.error('Failed to update asset name');
      }
  });

};


  /*
  const toggleColorPicker = (picker) => {
    setDisplayColorPicker({
      ...displayColorPicker,
      [picker]: !displayColorPicker[picker],
    });
  };

  const closeColorPickers = () => {
    setDisplayColorPicker({
      arrowUp: false,
      arrowDown: false,
      font: false,
      shadow: false,
    });
  };

  const handlePickerClick = (e, picker) => {
    e.stopPropagation();
    setDisplayColorPicker({
      ...displayColorPicker,
      [picker]: !displayColorPicker[picker],
    });
  };
  */

  return (
    <motion.div
      className="box"
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 1.5,
        ease: "easeOut",
        scale: {
          type: "spring",
          damping: 10,
          stiffness: 50,
          restDelta: 0.001,
        },
      }}
//    onClick={closeColorPickers}
    >
      <div className="d-flex justify-content-center align-items-center pt-5">
        <div className="container-fluid bg-main pb-5 mt-5">
          <div className="row mx-2">
            <div className="d-flex flex-wrap justify-content-center">
              <div className="col-lg-6 col-md-6 col-sm-12 gradient-arrow-container drop-shadow mx-1 my-1" style={{ minWidth: '350px' }}>
                <div className="col-12 p-0 position-relative d-flex justify-content-center">
                  <div className="d-flex align-items-center justify-content-center my-3" style={{ width: '100%' }}>
                    <img
                      className="clickable back-arrow-btn"
                      loading="lazy"
                      alt="back-btn"
                      src={BackBtn}
                      onClick={() => navigate("/dashboard")}
                      style={{ marginRight: '8px' }}
                    />
                    <input
                      type="text"
                      id="assetNameInput"
                      name="assetName"
                      className={`form-control flex-grow-1 asset-name-field ${!isEditing ? 'no-pointer-events' : ''}`}
                      value={assetInputName}
                      onChange={handleAssetInputNameChange}
                      placeholder="my-asset-name"
                    />
                    {isEditing ? (
                      <>
                        <button onClick={handleCancelClick} className="btn text-danger name-change ml-2">✕</button>
                        <button onClick={handleConfirmClick} className="btn text-success name-change ml-2">✓</button>
                      </>
                    ) : (
                      <img
                        className="clickable edit-asset-name-btn ml-2"
                        loading="lazy"
                        alt="Edit Asset Name"
                        src={Edit}
                        onClick={handleEditClick}
                      />
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-center pb-2">
                  <div
                    className="d-flex justify-content-center main-img-container"
                    style={{
                      ...backgroundStyle,
                      width: "auto",
                      height: "70%",
                      border: "1px solid #ccc",
                      overflow: "hidden"
                    }}
                  >
                    <img src={dynamicImgUrl} alt="Dynamic" className="radial-main" />
                  </div>
                </div>

                <div className="d-flex justify-content-center mt-3 mb-3">
                  <div
                    className="d-flex justify-content-center flex-wrap"
                    style={{ width: "100%" }}
                  >
                    <div className="col d-flex justify-content-center flex-wrap" style={{ overflow: "hidden" }}>
                      {dynamicImgUrl && (
                        <img
                          src={dynamicImgUrl}
                          alt="Dynamic Light"
                          className="bg-light p-1"
                          style={{
                            maxWidth: "20%",
                            height: "auto",
                            cursor: "pointer",
                            margin: "5px",
                          }}
                          onClick={handleWhiteBackground}
                        />
                      )}
                      {dynamicImgUrl && (
                        <img
                          src={dynamicImgUrl}
                          alt="Dynamic Dark"
                          className="p-1"
                          style={{
                            maxWidth: "20%",
                            height: "auto",
                            cursor: "pointer",
                            margin: "5px",
                          }}
                          onClick={handleBlackBackground}
                        />
                      )}
                      {dynamicImgUrl && (
                        <img
                          src={dynamicImgUrl}
                          alt="Dynamic Shadow Color"
                          className="p-1"
                          style={{
                            maxWidth: "20%",
                            height: "auto",
                            backgroundColor: shadowColor,
                            cursor: "pointer",
                            margin: "5px",
                          }}
                          onClick={handleShadowBackground}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 gradient-arrow-container drop-shadow mx-1 my-1" style={{ minWidth: '350px' }}>
                <div className="col-12 p-0">
                  <p className="toolbar-header gradient-toolbar mt-2 py-2 pl-3">
                    Size
                  </p>
                  <div className="col-12 p-0 position-relative d-flex justify-content-between mb-3">
                    <label htmlFor="sizeInput" className="toolbar-text ml-3 d-flex align-items-center">
                      Full Diameter
                    </label>
                    <div className="d-flex align-items-center justify-content-end" style={{ width: 'auto' }}>
                      <input
                        type="text"
                        id="sizeInput"
                        name="size"
                        className={`form-control flex-grow-1 diameter-input-field ${!isEditingSize ? 'no-pointer-events' : ''}`}
                        value={size}
                        onChange={handleInputChange}
                        placeholder="pixels"
                      />
                      {isEditingSize ? (
                        <>
                          <button onClick={handleConfirmClickSize} className="btn text-success size-change ml-2">✓</button>
                          <button onClick={handleCancelClickSize} className="btn text-danger size-change ml-2">✕</button>
                        </>
                      ) : (
                        <img
                          className="clickable edit-asset-name-btn ml-2"
                          loading="lazy"
                          alt="Edit Size"
                          src={Edit}
                          onClick={handleEditClickSize}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12 p-0">
                  <h5 className="toolbar-header gradient-toolbar py-2 pl-3">
                    Font
                  </h5>
                  <FontFamily pID="font-fam" pValue={fontFamily} pLabel="Font Family" pCallback={setFontFamily} />
                  
                  <div className="col-12 d-flex justify-content-between align-items-center divider-line toolbar-hover py-2">
                    <label className="toolbar-text">Font Color</label>
                    <div className="color-picker-container">
                    <ColorPopover 
                        initialColor={fontColor} 
                        inUseColors={[arrowUpColor, arrowDownColor, fontColor]}
                        saveChanges={(color) => handleColorChange(setFontColor, color)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 p-0">
                  <h5 className="toolbar-header gradient-toolbar mt-2 py-2 pl-3">
                    Dynamic Arrow
                  </h5>
                  <div className="col-12 d-flex justify-content-between align-items-center divider-line toolbar-hover pb-2">
                    <label className="toolbar-text">Up Color</label>
                    <div className="color-picker-container">
                      <ColorPopover 
                        initialColor={arrowUpColor} 
                        inUseColors={[arrowUpColor, arrowDownColor, fontColor]}
                        saveChanges={(color) => handleColorChange(setArrowUpColor, color)}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex justify-content-between align-items-center divider-line toolbar-hover py-2">
                    <label className="toolbar-text">Down Color</label>
                    <div className="color-picker-container">
                      <ColorPopover 
                        initialColor={arrowDownColor} 
                        inUseColors={[arrowUpColor, arrowDownColor, fontColor]}
                        saveChanges={(color) => handleColorChange(setArrowDownColor, color)}
                      />
                    </div>
                  </div>

                  <div className="col-12 d-flex justify-content-between align-items-center toolbar-hover py-2">
                    <label htmlFor="cutoffValueInput" className="toolbar-text">
                      Cutoff Value
                    </label>
                    <DelayedInput value={cutOffValue}  id="cutoffValueInput"  
                                  cNames="diameter-input-field"
                                  onChange={(v) => setCutOffValue(v)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Arrow;
