import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
//import Landing from "./Components/Landing";
import RadialBasic from "./Editors/RadialBasic";
import Arrow from "./Editors/Arrow";
import Bar from "./Editors/Bar";
import Hero from "./Components/Hero";
import DashboardManagerParent from "./Components/DashboardManagerParent";
import Documentation from "./Components/Documentation";
import Level2Tutorial from "./Components/Documentation/Level2Tutorial";
//import Login from "./Components/Login";
//import SignUp from "./Components/SignUp";
//import ConfirmAccount from "./Components/ConfirmAccount";
//import ConfirmCode from "./Components/ConfirmCode";
import ResetPassword from "./Components/ResetPassword";
import Pricing from "./Components/Pricing";
import TopNav from "./Components/TopNav";
import Footer from "./Components/Footer";
import Profile from "./Components/Profile";
import "./Components/prism-darcula.css";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-jsx";
import "prismjs/components/prism-json";
import QuickStart from "./Components/Documentation/GettingStarted/QuickStart";
import Introduction from "./Components/Documentation/GettingStarted/Introduction";
import WaysToUse from "./Components/Documentation/MD99Basics/WaysToUse";
import ApiBeginner from "./Components/Documentation/Tutorials/ApiBeginners";
import Database from "./Components/Documentation/Tutorials/Database";
import DynamicRouting from "./Components/Documentation/Tutorials/DynamicRouting";
import DynamicRoutingNode from "./Components/Documentation/Tutorials/DynamicRoutingNode";
import Onboarding from "./Components/Documentation/MD99Basics/Onboarding";
import Interface from "./Components/Documentation/MD99Basics/Interface";
import FirstAsset from "./Components/Documentation/MD99Basics/FirstAsset";
import CallingApi from "./Components/Documentation/ApiSpecs/CallingApi";
import ErrorHandling from "./Components/Documentation/ApiSpecs/ErrorHandling";
import Overview from "./Components/Documentation/ApiSpecs/Overview";
import QueryTypes from "./Components/Documentation/ApiSpecs/QueryTypes";
import MD99Assets from "./Components/Documentation/GettingStarted/MD99Assets";
//import Checkout from "./Components/Checkout"; // Import Checkout component


function App() 
{
  //const [assetsCount, setAssetsCount] = useState(0);
  //const resetAssetsCount = () => setAssetsCount(0);

  const [redrawIncrementer, setRedrawIncrementer] = useState(0);

  function forceRedraw ()
  {
console.log ("Force Redraw");
    setRedrawIncrementer (redrawIncrementer + 1);
  }

  return (
    <Router>
{/*   <TopNav assetsCount={assetsCount} resetAssetsCount={resetAssetsCount} />  */}
      <TopNav forceRedrawCallback={forceRedraw} />
      <Toaster position="top-right" /> {/* Setting the position to top-right */}
      <div className="App bg-main">
        <Routes>
          <Route path="/" element={<Hero forceRedrawCallback={forceRedraw} />} />
{/*       <Route path="/login" element={<Login loginSuccessCallback={forceRedraw} />} />  */}
{/*       <Route path="/signup" element={<SignUp />} />   */}
{/*       <Route path="/confirm-account" element={<ConfirmAccount />} />  */}
{/*       <Route path="/confirm-code" element={<ConfirmCode />} />    */}
          <Route path="/reset/password/:user_id/:reset_code" element={<ResetPassword />} />
          <Route path="/editor/radialbasic" element={<RadialBasic />} />
          <Route path="/editor/radialbasic/:asset_id" element={<RadialBasic />} />
          <Route path="/editor/arrow" element={<Arrow />} />
          <Route path="/editor/arrow/:asset_id" element={<Arrow />} />
          <Route path="/editor/bar" element={<Bar />} />
          <Route path="/editor/bar/:asset_id" element={<Bar />} />
{/*       <Route path="/dashboard" element={<DashboardManagerParent setAssetsCount={setAssetsCount} />} />  */}
          <Route path="/dashboard" element={<DashboardManagerParent forceRedrawCallback={forceRedraw} />} />
          <Route path="/documentation/intro" element={<Documentation />} />
          <Route path="/documentation/level2tutorial" element={<Level2Tutorial />} />
          <Route path="/documentation/introduction" element={<Introduction />} />
          <Route path="/documentation/quick_start" element={<QuickStart />} />
          <Route path="/documentation/md99_assets" element={<MD99Assets />} />
          <Route path="/documentation/first_asset" element={<FirstAsset />} />
          <Route path="/documentation/interface" element={<Interface />} />
          <Route path="/documentation/onboarding" element={<Onboarding />} />
          <Route path="/documentation/ways_to_use" element={<WaysToUse />} />
          <Route path="/documentation/api_beginners" element={<ApiBeginner />} />
          <Route path="/documentation/database" element={<Database />} />
          <Route path="/documentation/dynamic_routing" element={<DynamicRouting />} />
          <Route path="/documentation/dynamic_routing_node" element={<DynamicRoutingNode />} />
          <Route path="/documentation/overview" element={<Overview />} />
          <Route path="/documentation/calling_api" element={<CallingApi />} />
          <Route path="/documentation/error_handling" element={<ErrorHandling />} />
          <Route path="/documentation/query_types" element={<QueryTypes />} />
          <Route path="/info/profile" element={<Profile forceRedrawCallback={forceRedraw} />} />
          <Route path="/info/pricing" element={<Pricing />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
