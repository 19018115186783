export function buildColorList (zones, ...args)
{
   // zones is expected to be an array of objects
   // each object should have a "c" param
   var fullList = [];
   for (var i1 = 0; i1 < args.length; i1++) {
      if (!seenInList (fullList, args[i1])) {
         fullList.push (args[i1]);
      }
   }
   
   if (zones !== null) {
      for (var i1 = 0; i1 < zones.length; i1++) {
         if (!seenInList (fullList, zones[i1].c)) {
            fullList.push (zones[i1].c);
         }
      }
   }
   return fullList;
}

export function weedList (fullList, listMax, ...args)
{
   for (var i1 = 0; i1 < args.length; i1++) {
      if (fullList.length <= listMax)
         return fullList;

      const toRemove = args[i1];
      for (var i2 = 0; i2 < fullList.length; i2++) {
         if (fullList[i2] === toRemove) {
            fullList.splice (i2, 1);
         }
      }
   }

   // if there are still more than 6 colors
   if (fullList.length > 6) {
      fullList = fullList.slice(0, 6);
   }

   return fullList;
}

function seenInList (list, newValue)
{
   for (var i1 = 0; i1 < list.length; i1++) {
      if (list[i1] == newValue)
         return true;
   }
   return false;
}


export function formatStyles (origStyles)
{
   if (origStyles === null) {
      return emptyStyle ();
   }
   if (Array.isArray (origStyles)) {
      if (origStyles.length > 0)
         return origStyles;

      return emptyStyle ();
   }
   if (typeof origStyles === 'object') {
      if (Object.keys(origStyles).length > 0)
         return origStyles;

      return emptyStyle ();
   }

  return origStyles;
}

function emptyStyle ()
{
   return {zIndex: 'inherit'};
}


export function formatClasses (origCNames) 
{
   if (origCNames === null) {
      return emptyCName ();
   }
   if (typeof origCNames === 'string' || origCNames instanceof String) {
      return origCNames;
   }
   
   if (Array.isArray (origCNames)) {
      if (origCNames.length > 0)
         return origCNames;

      return emptyCName ();
   }

  return emptyCName;
}

function emptyCName ()
{
   return "empty-class";
}
