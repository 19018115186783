import React from "react";
import Sidebar from "../Sidebar";
import "../../../App.css";
import CodeSnippet from "../../Prism";
import BrowserSetup from "../../images/tutorial-browser-setup.png";
import ConsoleSetup from "../../images/tutorial-concept-setup.png";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-jsx";

const Interface = () => {

  return (
    <div className="bg-main text-white mt-5">
      <div className="row">
        <Sidebar />
        <div className="col doc-container">
          <div className="col mt-4">
            <h2 className="doc-caps">Introducing the MD99 Asset: Your Gateway to Effortless Data Visualization</h2>
            <h1 className="doc-header">MD99 Asset Builder and Dashboard</h1>
            
            <h1 className="doc-header">BUILD</h1>
            <h3 className="doc-sub-header mt-0 mb-4">
              Welcome to the MD99 Asset, a revolutionary tool designed to make data visualization seamless and efficient. With our dynamic data graphic builder, you can create stunning visuals that bring your data to life and integrate them instantly through an API call to our MD99 API.
            </h3>

            <h3 className="doc-sub-header mt-0 mb-4">
              The MD99 Asset is built with simplicity and innovation in mind. Our platform is your one-stop solution for transforming complex data into clear, engaging graphics that can be accessed via a simple image URL. Say goodbye to cumbersome processes and hello to server-side rendering that speeds up your development time.
            </h3>

            <h1 className="doc-header">DEPLOY</h1>
            <h3 className="doc-sub-header mt-0 mb-4">
              At the heart of this experience is our Asset Manager Dashboard. This powerful yet user-friendly interface is where your creativity meets functionality. Easily create and update individual and group assets, and manage your entire collection with just a few clicks. The dashboard also provides access to your public and secret keys, ensuring that you have everything you need to manage your assets securely.
            </h3>

            <h1 className="doc-header">REPEAT</h1>
            <h3 className="doc-sub-header mt-0 mb-4">
              Need to update a single-use asset? No problem. The MD99 Asset Manager allows for manual updates, giving you full control over how and when your graphics are refreshed. Whether you’re working on a solo project or collaborating with a team, our platform removes major obstacles, letting you focus on enhancing your data presentations.
            </h3>

            <h3 className="doc-sub-header mt-0 mb-4">
              Join us on this exciting journey and discover how the MD99 Asset can elevate your data graphics to new heights. With our intuitive design and cutting-edge technology, we make designing and deploying data visuals as simple and enjoyable as possible. Embrace the future of data visualization with MD99, where creativity and practicality merge seamlessly.
              Start creating with the MD99 Asset today and see how easy it is to turn your data into impactful visuals. Your data, our graphics – a perfect partnership for success.
            </h3>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Interface;
