import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

function Checkout() {
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");

  const handleSubmitPayment = (e) => {
    e.preventDefault();
    // Add your payment processing logic here
    // For now, we'll just log the input values
    console.log("Card Number:", cardNumber);
    console.log("Expiry Date:", expiry);
    console.log("CVC:", cvc);
    // Close the modal or proceed further
  };

  return (
    <div className="checkout-form-container">
      <h2 className="toolbar-header gradient-toolbar px-4 py-2">Checkout</h2>
      <form onSubmit={handleSubmitPayment} className="checkout-form w-100 p-3">
        <div className="form-label input-label-text">
          <label htmlFor="cardNumber">Card Number</label>
          <input
            type="text"
            className="form-control input-field-login p-3 m-0"
            id="cardNumber"
            placeholder="Card Number"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            required
          />
        </div>
        <div className="form-group mb-3 d-flex justify-content-between">
          <div className="flex-grow-1 mr-2 form-label input-label-text">
            <label htmlFor="expiry">Expiration Date</label>
            <input
              type="text"
              className="form-control input-field-login p-3 m-0"
              id="expiry"
              placeholder="MM/YY"
              value={expiry}
              onChange={(e) => setExpiry(e.target.value)}
              required
            />
          </div>
          <div className="flex-grow-1 form-label input-label-text">
            <label htmlFor="cvc">CVC</label>
            <input
              type="text"
              className="form-control input-field-login p-3 m-0"
              id="cvc"
              placeholder="CVC"
              value={cvc}
              onChange={(e) => setCvc(e.target.value)}
              required
            />
          </div>
        </div>
        <button type="submit" className="btn create-btn w-100">Submit Payment</button>
      </form>
    </div>
  );
}

export default Checkout;
