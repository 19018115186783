import React, { useState, useEffect, useRef } from 'react';
import './ColorPicker.css';
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { rgbToHex, hexToRgb, hexToHue, hslToHex, hueToHex, fullySaturateHex, 
         drawBigSwatch, drawCrossHairs, calcCoordOnGradient } from './ColorUtils.js'


function ColorPicker ({ initialHex, inUseColors, onColorChange, finished })
{
   const [hue, setHue] = useState(hexToHue(initialHex));
   const [hexText, setHexText] = useState(initialHex);

   const currentSwatch = useRef(null);
   const bigGradient   = useRef(null);

   const bigGW = 240;
   const bigGH = 100;
   const swatchRow = [ '#FF0000','#FF7F00','#FFFF00','#00FF00','#0000FF','#8B00FF','#000000','#FFFFFF',
                       '#FF9999','#FFFF99','#99FF99','#99FFFF','#9999FF','#FF99FF','#808080','#C0C0C0'];

   function okClicked ()
   {
      finished (hexText);
   }

   function cancelClicked ()
   {
      finished ("cancel");
   }

   function handleTextChange (e)
   {
      const asHex  = e.target.value
      setHexText (asHex);

      const rgbObj = hexToRgb (asHex);
      if (rgbObj === null)
         return;

      handleSwatchClick (asHex);
   }

   function handleSwatchClick (newColor)
   {
      onColorChange (newColor);
      setHexText (newColor);
      setHue (hexToHue(newColor));
      currentSwatch.current.style.backgroundColor = newColor;

      const bigGHex = fullySaturateHex (newColor);
      drawBigSwatch (bigGW, bigGH, bigGHex, bigGradient);
   }

   function handleHueChange (e)
   {
      const newHue = e.target.value;
      const newHex = hueToHex (newHue, hexText);
      onColorChange (newHue);
      setHue (newHue);
      setHexText (newHex);
      currentSwatch.current.style.backgroundColor = newHex;

      const bigGHex = hslToHex (newHue, 100, 50);
      drawBigSwatch (bigGW, bigGH, bigGHex, bigGradient);
   };

   function handleBigGradientClick (event) 
   {
      const context = bigGradient.current.getContext("2d");
      const imgData = context.getImageData (event.nativeEvent.offsetX, event.nativeEvent.offsetY, 1, 1);
      const asHex   = rgbToHex (imgData.data[0], imgData.data[1], imgData.data[2]);

      handleSwatchClick (asHex);
      drawCrossHairs (event.nativeEvent.offsetX, event.nativeEvent.offsetY, bigGradient);
   }

   
   useEffect(() => {
      const bigGHex = fullySaturateHex (initialHex);
      drawBigSwatch (bigGW, bigGH, bigGHex, bigGradient);

      // this just didn't work well
      //const gridXY = calcCoordOnGradient (bigGW, bigGH, bigGHex, initialHex);
      //if (gridXY !== null) {
         //drawCrossHairs (gridXY.x, gridXY.y, bigGradient);
      //}
   }, []);

    
   // ----------------------------------------------------------
   //       Short Cuts for Swatch div's 
   function buildSwatchDiv16 (tColor, index)
   {
      return doBuildSwatch (tColor, index, "swatch", "swatch-");
   }
   function buildSwatchDivUser (tColor, index)
   {
      return doBuildSwatch (tColor, index, "inuse_swatch", "u-swatch-");
   }

   function doBuildSwatch (tColor, index, cName, keyPrefix)
   {
      return (<div 
               className={cName}
               style={{ backgroundColor: tColor }} 
               onClick={() => handleSwatchClick (tColor)}  
               key={keyPrefix + "-" + index} >
              </div> );
   }


   return (
      <div className="color-picker-container d-flex justify-content-center">
         {/* -------------HEX VALUE ROW------------- */}
         <div className="cp_row d-flex justify-content-between align-items-center pb-1">
            <div className="d-flex">
               <label htmlFor="hexInput" className="color-picker-header pt-2 pr-1">Hex</label>
               <input
                  type="text"
                  value={hexText}
                  className="hex-input-field"
                  onChange={handleTextChange}
               />
            </div>

            <div className="d-flex">
               <button className="btn text-danger color-btn ml-2"  onClick={cancelClicked} >✕</button>
               <button className="btn text-success color-btn ml-2" onClick={okClicked} >✓</button>
            </div>
         </div>

         {/* -------------SWATCHES ROW------------- */}
         <div className="cp_swatch_row d-flex align-items-center pb-1">
            <div className="current_swatch" ref={currentSwatch} style={{ backgroundColor: initialHex }} ></div>
            
            <div className="swatches_container">
               {/* -------------DEFAULT SWATCHES ROW------------- */}
               <div className="user-swatches">
                  {swatchRow.map ((tColor, index) => buildSwatchDiv16 (tColor, index) )}
               </div>
            </div>
         </div>

         {/* -------------HUE SLIDER ROW------------- */}
         <div className="cp_row">
            <input
               type="range"
               min="0"
               max="360"
               value={hue}
               className="hue-slider"
               onChange={handleHueChange}
            />
         </div>

         {/* -------------SATURATION BOX ROW------------- */}
         <div className="cp_row pb-1">
            <div className="" style={{ width: bigGW, height: bigGH }} >
               <canvas  
                  ref={bigGradient}  
                  width={ bigGW }
                  height={ bigGH }
                  onClick={(e)=> handleBigGradientClick(e)}
               />               
            </div>
         </div>

        {/* -------------USER SWATCHES ROW------------- */}
        <div className="cp_last_row">
         <h5 className="toolbar-header gradient-toolbar mt-2 py-2 pl-3">+My Colors</h5>
          <div className="user-swatches">
             {inUseColors.map ((tColor, index) => buildSwatchDivUser (tColor, index) )}
          </div>
        </div>
      </div>
  );
};

export default ColorPicker;
