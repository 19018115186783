import React, { useRef } from "react";
import { formatStyles, formatClasses } from "../EditUtils/EditorUtils";

function FileUploader ({ btnText, classNamesStr, stylesList, onChange }) 
{
   const domRef = React.createRef();

   classNamesStr = formatClasses (classNamesStr);
   stylesList    = formatStyles (stylesList);


   function btnClicked () 
   {
      domRef.current.value = '';
      domRef.current.click();
   }


   return (
      <div>
         <button  className={classNamesStr}  style={ stylesList }  onClick={btnClicked}>{ btnText }</button>
         <input  type="file"  hidden  accept="image/*"  onChange={onChange}  ref={domRef} />
      </div>
   );
}

export default FileUploader;