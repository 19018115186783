import React from "react";
import Sidebar from "../Sidebar";
import "../../../App.css";
import CodeSnippet from "../../Prism";
import BrowserSetup from "../../images/tutorial-browser-setup.png";
import ConsoleSetup from "../../images/django-directory.png";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-jsx";
import AssetBuilder from "../../images/asset-builder.png";
import AssetDashboard from "../../images/asset-dashboard.png";

import DividerLine from "../../images/divider-line.svg";

const MD99DGETutorial = () => {

  const installDependencies = `
    npm install
  `;

  const runServer = `
    node app.js
  `;

  const appJsCode = `
    const http = require('http');
    const Controller = require('./controller');

    const port = 3000;

    const server = http.createServer((request, response) => {
      if (request.method == 'OPTIONS') {
        if (Controller.isUrlMatch(request, '/api/get/imageurl') ||
            Controller.isUrlMatch(request, '/api/personal/imageurl') ||
            Controller.isUrlMatch(request, '/api/student/imageurl')) {
          response.setHeader('Access-Control-Allow-Origin', '*');
          response.setHeader('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE');
          response.setHeader('Access-Control-Allow-Headers', 'Content-Type');
          response.setHeader('Access-Control-Allow-Credentials', true);
          response.writeHead(200);
          response.end();
        } else {
          response.writeHead(404);
          response.end();
        }
      }

      if (request.method == 'GET') {
        request.on('data', function(data) {});

        request.on('end', function() {
          console.log("Processing GET request");

          response.setHeader('Access-Control-Allow-Origin', '*');
          response.setHeader('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE');
          response.setHeader('Access-Control-Allow-Headers', 'Content-Type');
          response.setHeader('Access-Control-Allow-Credentials', true);

          sendPlainHtml(response, "Got GET request at the server");
        });
      }

      if (request.method == 'POST') {
        var postBody = "";
        request.on('data', function(data) {
          postBody += data;
        });

        request.on('end', function() {
          if (Controller.isUrlMatch(request, '/api/get/imageurl')) {
            Controller.singleImageUrl(postBody, function(url) {
              sendPlainHtml(response, url);
            });
          }

          if (Controller.isUrlMatch(request, '/api/personal/imageurl')) {
            Controller.personalImageUrl(postBody, function(url) {
              sendPlainHtml(response, url);
            });
          }

          if (Controller.isUrlMatch(request, '/api/student/imageurl')) {
            Controller.studentUrlArray(postBody, function(urlArray) {
              sendArrayAsPlainHtml(response, urlArray);
            });
          }
        });
      }
    });

    function sendPlainHtml(responseObj, theText) { 
      responseObj.setHeader('Access-Control-Allow-Origin', '*');
      responseObj.setHeader('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE');
      responseObj.setHeader('Access-Control-Allow-Headers', 'Content-Type');
      responseObj.setHeader('Access-Control-Allow-Credentials', true);

      responseObj.writeHead(200, {"Content-Type": "text/plain"});
      responseObj.end(theText);
    }

    function sendArrayAsPlainHtml(responseObj, theArray) { 
      const theText = JSON.stringify(theArray);
      sendPlainHtml(responseObj, theText);
    }

    server.listen(port);
    console.log("MD99-http Server Demo is listening on port " + port);
  `;

  const controllerJsCode = `
    const MD99Auth = require('./sdk/src/md99-auth/md99-auth');
    const Data = require('./data');

    const publicKey = "60c7cde37d7834068978";
    const secretKey = "5930672324e8a352873255b474267fe7";

    exports.singleImageUrl = singleImageUrl;
    exports.personalImageUrl = personalImageUrl;
    exports.studentUrlArray = studentUrlArray;
    exports.isUrlMatch = isUrlMatch;

    function singleImageUrl(postParams, urlCallback) {
      const postObj = processPostParams(postParams);
      const missingParam = findMissingParam(postObj, ["value", "asset_name"]);
      if (missingParam != null) {
        console.log(missingParam);
        urlCallback(MD99Auth.errorImageURL());
        return;
      }

      MD99Auth.getMD99AuthToken(publicKey, secretKey, function(success, token, errMsg) {
        if (!success) {
          console.log(errMsg);
          urlCallback(MD99Auth.errorImageURL());
          return;
        }
        const url = MD99Auth.createImageURL(publicKey, token, postObj.value, postObj.asset_name);
        urlCallback(url);
      });
    }

    function personalImageUrl(postParams, urlCallback) {
      const postObj = processPostParams(postParams);
      const missingParam = findMissingParam(postObj, ["user_name"]);
      if (missingParam != null) {
        console.log(missingParam);
        urlCallback(MD99Auth.errorImageURL());
        return;
      }

      const assetName = "radial-simple";
      const localValue = Data.getPersonalScore(postObj['user_name']);
      
      MD99Auth.getMD99AuthToken(publicKey, secretKey, function(success, token, errMsg) {
        if (!success) {
          console.log(errMsg);
          urlCallback(MD99Auth.errorImageURL());
          return;
        }
        const url = MD99Auth.createImageURL(publicKey, token, localValue, assetName);
        urlCallback(url);
      });
    }

    function studentUrlArray(postParams, urlCallback) {
      const postObj = processPostParams(postParams);
      const missingParam = findMissingParam(postObj, ["student_id"]);
      if (missingParam != null) {
        console.log(missingParam);
        urlCallback(MD99Auth.errorImageURL());
        return;
      }

      const studentScores = Data.getStudentScores(postObj['student_id']);

      MD99Auth.getMD99AuthToken(publicKey, secretKey, function(success, token, errMsg) {
        if (!success) {
          console.log(errMsg);
          urlCallback(MD99Auth.errorImageURL());
          return;
        }
        const fullAr  = studentImages(publicKey, token, studentScores);
        const fullObj = {image_data: fullAr};
        urlCallback(fullObj);
      });
    }

    function studentImages(publicKey, token, scoresAr) {
      var retAr = [];
      for (let key in scoresAr) {
        const score = scoresAr[key];
        const tag1  = key + "1";
        const tag2  = key + "2";
        const cName = Data.getCourseName(key);
        const rank  = Data.getCourseRank(key, score);
        const newRecord1 = courseScoreArray(publicKey, token, tag1, score, cName);
        const newRecord2 = courseScoreArray(publicKey, token, tag2, rank,  cName);
        retAr.push(newRecord1);
        retAr.push(newRecord2);
      }
      return retAr;
    }

    function courseScoreArray(publicKey, token, tag, score, courseName) {
      var assetName = "unknown";
      switch (tag) {
        case "sp1":
        case "hist1":
        case "eng1":
        case "calc1":
        case "chem1":
        case "phys1":
          assetName = "radial-simple";
          break;
        case "sp2":
        case "hist2":
        case "eng2":
        case "calc2":
        case "chem2":
        case "phys2":
          assetName = "bar-simple";
          break;
      }
      const url = MD99Auth.createImageURL(publicKey, token, score, assetName);
      return {img_id: tag, score: score, url: url, course_name: courseName};
    }

    function processPostParams(postParams) {
      const decodedData = decodeURIComponent(postParams);
      var postObj = {dummy_param: 123};
      try {
        postObj = JSON.parse(decodedData);
      } catch (err) {
      }
      return postObj;
    }

    function findMissingParam(inputObj, paramNameAr) {
      for (var i1 = 0; i1 < paramNameAr.length; i1++) {
        const paramName = paramNameAr[i1];
        if (!inputObj.hasOwnProperty(paramName)) {
          return ("Missing param: " + paramName);
        }
      }
      return null;
    }

    function isUrlMatch(request, myPath) {
      const pathOnly = require('node:url').parse(request.url).pathname;
      var path1 = pathOnly.toLowerCase();
      var path2 = myPath.toLowerCase();
      path1 = stripBegEndSlash(path1);
      path2 = stripBegEndSlash(path2);
      return (path1 == path2);
    }

    function stripBegEndSlash(srcStr) {
      if (srcStr.substr(0,1) == "/") {
        srcStr = srcStr.substr(1);
      }
      if (srcStr.substr(srcStr.length - 1) == "/") {
        srcStr = srcStr.substr(0, srcStr.length - 1);
      }
      return srcStr;
    }
  `;

  const dataJsCode = `
    exports.getPersonalScore = getPersonalScore;
    exports.getStudentScores = getStudentScores;
    exports.getCourseName = getCourseName;
    exports.getCourseRank = getCourseRank;

    function getPersonalScore(userName) {
      switch (userName.toLowerCase()) {
        case "betty" :
          return 90;
        case "billy" :
          return 50;
        case "john" :
          return 75;
      }
      return 0;
    }

    function getStudentScores(studentID) {
      const idStr = studentID.toString();
      switch (idStr) {
        case "1" :
          return {sp: 90, hist: 91, eng: 92, calc: 87, chem: 88, phys: 89};
        case "2" :
          return {sp: 94, hist: 95, eng: 94, calc: 94, chem: 95, phys: 94};
        case "3" :
          return {sp: 80, hist: 75, eng: 69, calc: 62, chem: 71, phys: 65};
        case "4" :
          return {sp: 99, hist: 99, eng: 99, calc: 99, chem: 99, phys: 99};
        case "5" :
          return {sp: 40, hist: 45, eng: 49, calc: 32, chem: 31, phys: 35};
        case "6" :
          return {sp: 88, hist: 87, eng: 88, calc: 92, chem: 91, phys: 92};
        case "7" :
          return {sp: 77, hist: 77, eng: 77, calc: 78, chem: 78, phys: 78};
        case "8" :
          return {sp: 80, hist: 75, eng: 69, calc: 62, chem: 71, phys: 65};
        case "9" :
          return {sp: 72, hist: 69, eng: 70, calc: 39, chem: 42, phys: 38};
        case "10" :
          return {sp: 90, hist: 92, eng: 93, calc: 85, chem: 88, phys: 82};
        case "11" :
          return {sp: 78, hist: 76, eng: 74, calc: 71, chem: 74, phys: 69};
        case "12" :
          return {sp: 80, hist: 77, eng: 75, calc: 99, chem: 94, phys: 96};
        case "13" :
          return {sp: 99, hist: 94, eng: 96, calc: 71, chem: 73, phys: 68};
        case "14" :
          return {sp: 66, hist: 55, eng: 53, calc: 48, chem: 48, phys: 44};
        case "15" :
          return {sp: 77, hist: 88, eng: 85, calc: 91, chem: 85, phys: 82};
        case "16" :
          return {sp: 68, hist: 69, eng: 68, calc: 65, chem: 64, phys: 65};
        case "17" :
          return {sp: 88, hist: 87, eng: 85, calc: 92, chem: 93, phys: 90};
        case "18" :
          return {sp: 62, hist: 65, eng: 66, calc: 68, chem: 69, phys: 66};
        case "19" :
          return {sp: 92, hist: 75, eng: 74, calc: 71, chem: 92, phys: 73};
        case "20" :
          return {sp: 99, hist: 98, eng: 97, calc: 81, chem: 82, phys: 80};
      }
      return {sp:0, hist:0, eng:0, calc:0, chem:0, phys:0};
    }

    function getCourseName(tag) {
      switch (tag) {
        case "sp":
        case "sp1":
        case "sp2":
          return "Spanish 3";
        case "hist":
        case "hist1":
        case "hist2":
          return "AP US Hist";
        case "eng":
        case "eng1":
        case "eng2":
          return "AP Literature";
        case "calc":
        case "calc1":
        case "calc2":
          return "AP Calc A";
        case "chem":
        case "chem1":
        case "chem2":
          return "AP Chemistry";
        case "phys":
        case "phys1":
        case "phys2":
          return "Physics 1";
      }
      return "Unknown Course";
    }

    function getRawRank(score) {
      if (score <= 30) {
        return 5;
      }
      if (score <= 35) {
        return 10;
      }
      if (score <= 40) {
        return 15;
      }
      if (score <= 44) {
        return 20;
      }
      if (score <= 48) {
        return 25;
      }
      if (score <= 52) {
        return 30;
      }
      if (score <= 56) {
        return 35;
      }
      if (score <= 60) {
        return 40;
      }
      if (score <= 64) {
        return 45;
      }
      if (score <= 68) {
        return 50;
      }
      if (score <= 72) {
        return 55;
      }
      if (score <= 75) {
        return 60;
      }
      if (score <= 78) {
        return 65;
      }
      if (score <= 81) {
        return 70;
      }
      if (score <= 84) {
        return 75;
      }
      if (score <= 87) {
        return 80;
      }
      if (score <= 90) {
        return 85;
      }
      if (score <= 93) {
        return 90;
      }
      if (score <= 96) {
        return 95;
      }
      return 99;
    }

    function getCourseRank(tag, score) {
      let offset = 0;
      switch (tag) {
        case "sp":
        case "sp1":
        case "sp2":
          offset = 4;
          break;
        case "hist":
        case "hist1":
        case "hist2":
          offset = 2;
          break;
        case "eng":
        case "eng1":
        case "eng2":
          offset = 0;
          break;
        case "calc":
        case "calc1":
        case "calc2":
          offset = -4;
          break;
        case "chem":
        case "chem1":
        case "chem2":
          offset = -2;
          break;
        case "phys":
        case "phys1":
        case "phys2":
          offset = -4;
          break;
      }
      return getRawRank(score - offset);
    }
  `;

  const md99AuthJsCode = `
    const crypto = require('node:crypto');
    const fs = require('node:fs');
    const axios = require('axios');

    const gAuthTokenUrl = "/api/get/authtoken";
    const gErrorImgUrl = "/images/image_not_found.png";
    const gRemoteUrl = "https://mydesign99.com";

    exports.createImageURL = createImageURL;
    exports.errorImageURL = errorImageURL;
    exports.getMD99AuthToken = getMD99AuthToken;

    function createImageURL(publicKey, token, value, longAssetName) {
      const assetName = stripAssetName(longAssetName);
      return (gRemoteUrl + "/get/" + publicKey + "/" + token + "/" + value + "/" + assetName + ".png");
    }

    function errorImageURL() {
      return (gRemoteUrl + gErrorImgUrl);
    }

    function getMD99AuthToken(publicKey, secretKey, tokenCallback) {
      const tokenObj = readTokenFromCache();
      if (tokenObj.success) {
        tokenCallback(true, tokenObj.token, "");
        return;
      }

      const payloadAr = { 'client_id': publicKey };
      const fullJwt = buildJWT(payloadAr, secretKey)
      const params = new URLSearchParams({ 'jwt': fullJwt });
      const postUrl = gRemoteUrl + gAuthTokenUrl;

      axios.post(postUrl, params)
          .then(function (axResponse) {
              const retData = parseTokenFromJMCResult(axResponse.data);
              if (!retData.success) {
                  tokenCallback(false, "", retData.message);
                  return;
              }
              writeTokenToCache(retData);
              tokenCallback(true, retData.token, "");
          })
          .catch(function (error) {
              tokenCallback(false, "", error);
          });
    }

    function buildJWT(payloadAsAr, secret) {
      const hdrAr = { "alg": "HS256", "typ": "JWT" };
      const hdr64 = arrayTo64(hdrAr);
      const pay64 = arrayTo64(payloadAsAr);
      const full64 = hdr64 + "." + pay64;
      const sign64 = crypto.createHmac('sha256', secret)
          .update(full64)
          .digest('base64');

      let fullJWT = full64 + "." + sign64;
      fullJWT = fullJWT.replaceAll("+", "-");
      fullJWT = fullJWT.replaceAll("/", "_");
      if (fullJWT.substring(fullJWT.length - 1) == "=") {
          fullJWT = fullJWT.substring(0, fullJWT.length - 1);
      }

      return fullJWT;
    }

    function arrayTo64(srcAr) {
      const asStr = JSON.stringify(srcAr);
      return Buffer.from(asStr).toString('base64');
    }

    function parseTokenFromJMCResult(responseData) {
      if (!responseData.hasOwnProperty("is_success"))
          return formatError("Invalid response format (s)");
      if (responseData.is_success != "1") {
          if (!responseData.hasOwnProperty("err_msg"))
              return formatError("Invalid response format (em)");
          return formatError(responseData.err_msg);
      }

      if (!responseData.hasOwnProperty("data"))
          return formatError("Invalid response format (data)");

      const data = responseData.data
      if (!data.hasOwnProperty("token"))
          return formatError("Invalid response format (token)");

      return formatSuccess(responseData.data);
    }

    function formatSuccess(retObj) {
      retObj.success = true;
      return retObj;
    }

    function formatError(msg) {
      const retObj = { success: false, message: msg };
      return retObj;
    }

    function stripAssetName(name) {
      name = name.replaceAll(" ", "-");
      name = name.toLowerCase();
      name = name.replace(/[^-a-z0-9_]/g, "");
      name = name.replace(/\-+/g, '-');
      name = name.replace(/^\-+|\-+$/g, "");
      return name;
    }

    function writeTokenToCache(dataAr) {
      const path = 'md99_data.txt';
      const jsonStr = JSON.stringify(dataAr);
      fs.writeFile(path, jsonStr, function (err) {
        if (err) {
          console.log("Error writing token data to file: " + err);
        }
      });
    }

    function readTokenFromCache() {
      const path = 'md99_data.txt';

      if (!fs.existsSync(path)) {
        return { success: false };
      }

      let jsonStr;
      try {
        jsonStr = fs.readFileSync(path, 'utf8');
      } catch (err) {
        return { success: false };
      }

      if (jsonStr.length == 0) {
        return { success: false };
      }

      let jsonObj;
      try {
        jsonObj = JSON.parse(jsonStr);
      } catch {
        return { success: false };
      }

      if (!jsonObj.hasOwnProperty('expires')) {
        return { success: false };
      }
      const curTime = Date.now() / 1000;
      if (curTime > jsonObj.expires) {
        return { success: false };
      }

      jsonObj.success = true;
      return jsonObj;
    }
  `;

  return (
    <div className="bg-main text-white mt-5">
      <div className="row">
        <Sidebar />
        <div className="col doc-container">
          <div className="col mt-4">
            <h2 className="doc-caps">MD99 DGE API Tutorial</h2>
            <h1 className="doc-header">Integrate MD99 DGE API with Node.js</h1>
            <h3 className="doc-sub-header">
              Welcome to the MD99 Data Graphics Engine (DGE) API tutorial! Learn how to integrate MD99 DGE into your Node.js project to create and manage dynamic data graphics securely.
            </h3>
          </div>

          <div className="px-5">
            <h1 className="doc-body-large">Learning Objectives</h1>
            <ol className="footer-bg py-4">
              <li className="doc-body-medium p-1">Understand the directory structure.</li>
              <li className="doc-body-medium p-1">Set up and install dependencies.</li>
              <li className="doc-body-medium p-1">Understand the purpose of key files.</li>
              <li className="doc-body-medium p-1">Learn to use `md99-auth.js` for secure data exchange.</li>
              <li className="doc-body-medium p-1">Create and manage secure data graphics.</li>
            </ol>

            <img
              src={DividerLine}
              alt="Divider line"
              style={{ width: "100%", height: "auto" }}
              className="mt-5"
            />


            <div className="snippet-container mt-5">
            <h4 className="doc-header">Fullstack Project Template</h4>
            <p className="doc-sub-header">
                To make your development process smoother, we provide a complete fullstack project template that you can open and use directly. We provide you with templates for both the Node.js backend and the React frontend, fully integrated to demonstrate the functionality of the MD99 Graphics Engine API. You can view and use the entire project or individual files as needed.
            </p>
            
            <p className="doc-body-medium mt-5">
                You can access the Node.js backend project template by clicking on the link below:
            </p>
            <a href="https://github.com/LakeshoreTim/MD99_NodeDemo" target="_blank">Open Node.js Template</a>
            </div>

            <div className="snippet-container mt-5">
            <p className="doc-body-medium">
                You can access the React Asset Display project template by clicking on the link below:
            </p>
            <a href="https://github.com/JMC818386/MD99-React-Demo" target="_blank">Open React Template</a>
            </div>

            <img
                src={DividerLine}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mt-5"
            />

            {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <div className="snippet-container  mt-5 pt-3">
              <h4 className="doc-header">Phase 1: Building a Custom Data Graphic</h4>
              <p className="doc-body-medium">Navigate to our website where you can create custom data graphics. Utilize the no-code editor to design and customize your graphic.</p>
              <img
                src={AssetBuilder}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mt-2mb-5"
              />
              <p className="doc-body-medium">Once created, your graphic will be instantly available through our API.</p>
              
              <h4 className="doc-header mt-5 pt-3">Retrieve Your API Keys</h4>
              <p className="doc-body-medium">Navigate to your My Assets Dashboard and copy your public_key and secret_key.</p>
               <img
                src={AssetDashboard}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mt-2 mb-5"
              />
              <p className="doc-body-medium m-2 pb-5">You will need to insert these keys into your django project in order to handle authenitcation while making calls to our API.</p>
            </div>

            <img
                src={DividerLine}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mt-4 mb-5"
              />

{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}


            <div className="snippet-container mt-5">
              <h4 className="doc-header">Setup Instructions</h4>

              <p className="doc-body-large">
                Install Dependencies:
              </p>
              <CodeSnippet code={installDependencies} language="bash" />
              <p className="doc-sub-header mt-2">Run the above command to install the necessary dependencies.</p>

              <p className="doc-body-large">
                Run the Server:
              </p>
              <CodeSnippet code={runServer} language="bash" />
              <p className="doc-sub-header mt-2">Use this command to start the server.</p>
            </div>

            <img
              src={DividerLine}
              alt="Divider line"
              style={{ width: "100%", height: "auto" }}
              className="mt-5"
            />

            <div className="snippet-container mt-5">
                <h4 className="doc-header">Key Files Overview</h4>
                <h4 className="doc-header">Api.js</h4>
                <h3 className="doc-sub-header">
                    The heart of our Node.js application lies within app.js. This file sets the stage for seamless interaction between the server and the client. By setting up an HTTP server, app.js routes incoming requests to the appropriate controllers, ensuring that data flows smoothly and efficiently. Whether handling GET, POST, or OPTIONS requests, this file orchestrates the communication needed to bring our data graphics engine to life.
                </h3>

              <h5 className="doc-body-large mt-4">`app.js`</h5>
              <CodeSnippet code={appJsCode} language="javascript" />
              <p className="doc-sub-header mt-2">
                Description:
                <ul>
                  <li>Imports and sets up the HTTP server and routes requests.</li>
                  <li>Handles `OPTIONS`, `GET`, and `POST` requests with CORS headers.</li>
                  <li>Utility functions to send responses in different formats.</li>
                  <li>Starts the server and listens on the specified port.</li>
                </ul>
              </p>

              <img
                src={DividerLine}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mt-4 mb-4"
              />

              <h4 className="doc-header">Controller.js</h4>
              <h3 className="doc-sub-header">
                The brains behind our operation reside in controller.js. Acting as the intermediary between the server and our data, this file processes incoming requests and interacts directly with the MD99 DGE API. It handles the heavy lifting of data processing, making sure that each request is met with the appropriate response. From generating image URLs to managing student data, controller.js is crucial for delivering dynamic and personalized data graphics.
                </h3>
              <h5 className="doc-body-large mt-4">`controller.js`</h5>
              <CodeSnippet code={controllerJsCode} language="javascript" />
              <p className="doc-sub-header mt-2">
                Description:
                <ul>
                  <li>Imports necessary modules and sets up constants for authentication.</li>
                  <li>Handles different types of requests to interact with the MD99 DGE API.</li>
                  <li>Utility functions to process POST parameters and match URLs.</li>
                  <li>Defines functions to handle image URL requests and process student data.</li>
                </ul>
              </p>

              <img
                src={DividerLine}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mt-4 mb-4"
              />

              <h4 className="doc-header">Data.js</h4>
              <h3 className="doc-sub-header">
                Behind every dynamic data graphic is a robust dataset, and data.js is where it all begins. This file provides mock data and utility functions, simulating the backend data that drives our graphics. By offering a structured and accessible way to retrieve personal scores, student scores, course names, and course ranks, data.js ensures that our application has the foundational data it needs to create meaningful visualizations.
                </h3>
              <h5 className="doc-body-large mt-4">`data.js`</h5>
              <CodeSnippet code={dataJsCode} language="javascript" />
              <p className="doc-sub-header mt-2">
                Description:
                <ul>
                  <li>Provides mock data and utility functions.</li>
                  <li>Defines functions to retrieve personal scores, student scores, course names, and course ranks.</li>
                </ul>
              </p>

              <img
                src={DividerLine}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mt-4 mb-4"
              />

              <h4 className="doc-header">md99-auth</h4>
              <h3 className="doc-sub-header">
                Security and authentication are paramount in any data-driven application, and md99-auth.js is our gatekeeper. This file handles the critical tasks of authentication and token management, ensuring that our data exchanges are secure and authorized. By generating JWT tokens, managing token caching, and creating secure image URLs, md99-auth.js safeguards our application and ensures that only authorized users can access and manipulate our data graphics.
                </h3>
              <h5 className="doc-body-large mt-4">`md99-auth.js`</h5>
              <CodeSnippet code={md99AuthJsCode} language="javascript" />
              <p className="doc-sub-header mt-2">
                Description:
                <ul>
                  <li>Handles authentication and token management.</li>
                  <li>Defines functions to create image URLs, handle errors, and get authentication tokens.</li>
                  <li>Includes utility functions to build JWTs and manage token caching.</li>
                </ul>
              </p>
            </div>

            <img
              src={DividerLine}
              alt="Divider line"
              style={{ width: "100%", height: "auto" }}
              className="mt-4 mb-4"
            />

            <div className="snippet-container mt-5">
              <h4 className="doc-header">Summary</h4>
              <p className="doc-sub-header">
                Congratulations! You have successfully integrated the MD99 DGE API into your Node.js project. Feel free to use the provided templates to start building your own projects and explore the many ways you can use our graphics engine to enhance your web applications. Happy coding!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MD99DGETutorial;
