import React from "react";
import Sidebar from "../Sidebar";
import "../../../App.css";
import CodeSnippet from "../../Prism";
import BrowserSetup from "../../images/tutorial-browser-setup.png";
import ConsoleSetup from "../../images/django-directory.png";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-jsx";

import AssetBuilder from "../../images/asset-builder.png";
import AssetDashboard from "../../images/asset-dashboard.png";
import DjangoDirectory from "../../images/django-directory.png";
import ReactDirectory from "../../images/react-directory.png";
import FinalRender from "../../images/final-render.png";
import DividerLine from "../../images/divider-line.svg";
import RadialMain from "../../images/radial_main_test.png";
import BarMain from "../../images/bar-doc-banner.png";
import ArrowMain from "../../images/arrow-doc-banner.png";

const MD99Assets = () => {




  return (
    <div className="bg-main text-white mt-5">
      <div className="row">
        <Sidebar />
        <div className="col doc-container">
          <div className="col mt-4">
            <h2 className="doc-caps">Simplified Overview of Key Performace Metrics</h2>
            <h1 className="doc-header">MD99 Components</h1>
            <h2 className="doc-caps">Radial, Bar, and Arrow Assets</h2>
            <h3 className="doc-sub-header">
              When it comes to effectively communicating key performance metrics, using the right visual tools can make all the difference. Our radial, bar, and arrow assets stand out for their clarity and ease of understanding.
            </h3>
          </div>

          <div className="px-5">
        
            <img
              src={DividerLine}
              alt="Browser setup"
              style={{ width: "100%", height: "auto" }}
              className="mt-5"
            />


{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <div className="snippet-container mt-5">
              <h4 className="doc-header">The Radial Asset</h4>
              <h3 className="doc-sub-header">The Power of Percentage Representation</h3>
              <img
                className="flex-shrink-0 my-3 banner"
                src={RadialMain}
                loading="lazy"
                alt=""
              />
              <p className="doc-body-small">
              Our Radial assets (radial progress indicators) are particularly effective for representing percentages. They provide a clear and immediate understanding of completion rates or performance metrics. The circular design allows for a quick glance to determine progress, making it ideal for dashboards where users need to assess status instantly. Best practices for radial assets include:
              </p>
              
              <p className="doc-body-large pt-3">Percentage Representation</p>
              <p className="doc-body-small mt-2">Displaying the percentage as a number in combination with the graphically rendered asset creates a powerful focal point, guiding the viewers eyes to the information you want them to see immediately.</p>

              <p className="doc-body-large pt-3">Color Coding</p>
              <p className="doc-body-small mt-2">Assigning colors to different value ranges can help to indicate contrasting performance levels helps to enhance impact and comprehension (e.g., green for good, yellow for caution, red for critical).</p>

              <p className="doc-body-large pt-3">Consistent Scalet</p>
              <p className="doc-body-small mt-2">Keeping a consistent scale across gauges helps users compare metrics quickly.</p>
            </div>

            <img
                src={DividerLine}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mt-5"
              />

{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            
            <div className="snippet-container mt-5">
              <h4 className="doc-header">The Bar Asset</h4>
              <h3 className="doc-sub-header">Complementing and Contrasting Radial Assets</h3>
              <p className="doc-body-small">
              Bar Assets (bar progress indicators) are a versatile tool for enhancing your data presentation. They excel in maximizing space efficiency and adding depth to your other assets. Here's why Bar Assets are indispensable:
              </p>
              <img
                className="flex-shrink-0 my-3 banner"
                src={BarMain}
                loading="lazy"
                alt=""
              />

              <p className="doc-body-large pt-3">Direct Comparison</p>
              <p className="doc-body-small mt-2">Bars allow for easy comparison between different data points, making it simple to identify trends and outliers.</p>

              <p className="doc-body-large pt-3">Visual Hierarchy</p>
              <p className="doc-body-small mt-2">The length of the bars provides a straightforward visual hierarchy, guiding the viewer's eye to the most significant data.</p>

              <p className="doc-body-large pt-3">Detail and Depth</p>
              <p className="doc-body-small mt-2">While radial gauges offer a high-level view, Bar Assets can provide more detail, showing exact values and facilitating deeper analysis.</p>

              <p className="doc-body-large pt-3">Space Efficiency</p>
              <p className="doc-body-small mt-2">Bar Assets can be used in various layouts and designs, allowing for a compact presentation without sacrificing clarity or impact.</p>

              <p className="doc-body-large pt-3">Flexibility</p>
              <p className="doc-body-small mt-2">Whether you need to display a simple quantity or a complex comparison, Bar Assets can be adapted to suit your specific needs, giving you full control over how you present your insights.</p>
            </div>

            <img
              src={DividerLine}
              alt="Browser setup"
              style={{ width: "100%", height: "auto" }}
              className="mt-5"
            />

{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            
            <div className="snippet-container mt-5">
              <h4 className="doc-header">The Arrow Asset</h4>
              <h3 className="doc-sub-header">Directional Insights at a Glance</h3>
              <img
                className="flex-shrink-0 my-3 banner"
                src={ArrowMain}
                loading="lazy"
                alt=""
              />
              <p className="doc-body-small">
              Our Arrow assets (directional progress indicators) are perfect for showing changes and trends. Whether pointing up, down, or sideways, arrows convey movement and direction effectively:
              </p>

              <p className="doc-body-large pt-3">Trend Indicators</p>
              <p className="doc-body-small mt-2">Use arrows to highlight increasing, decreasing, or stable trends in data.</p>

              <p className="doc-body-large pt-3">Quick Status Updates</p>
              <p className="doc-body-small mt-2">Arrows can provide a quick snapshot of performance changes, supplementing the information shown by radial gauges and bar graphs.</p>

              <p className="doc-body-large pt-3">Immediate Interpretation</p>
              <p className="doc-body-small mt-2">The intuitive nature of arrows makes them instantly recognizable and easy to understand.</p>
            </div>

            <img
              src={DividerLine}
              alt="Browser setup"
              style={{ width: "100%", height: "auto" }}
              className="mt-5"
            />

{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            
            <div className="snippet-container mt-5">
              <h4 className="doc-header">Bringing it all together</h4>
              <p className="doc-body-small">
              Combining these three types of graphics creates a comprehensive and intuitive visual representation of data:
              </p>

              <p className="doc-body-large pt-3">Radial Assets</p>
              <p className="doc-body-small mt-2">provide a top-level percentage view, understandable at a glance..</p>

              <p className="doc-body-large pt-3">Bar Asset</p>
              <p className="doc-body-small mt-2">offer detailed comparisons and depth.</p>

              <p className="doc-body-large pt-3">Arrow Asset</p>
              <p className="doc-body-small mt-2">how directional trends and changes.</p>
            </div>

            <img
              src={DividerLine}
              alt="Browser setup"
              style={{ width: "100%", height: "auto" }}
              className="mt-5"
            />
         

{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <div className="snippet-container mt-5">
              <h4 className="doc-header">Summary</h4>
              <p className="doc-sub-header">
              By integrating these elements, users can quickly grasp key metrics at a glance and delve deeper into the data as needed. The visual hierarchy developed through this combination ensures that information is communicated effectively and intuitively, enhancing overall understanding, driving informed decision-making.
              </p>

              <p className="doc-sub-header">
              This approach ensures that data visualization not only looks appealing but also functions as a powerful tool for conveying complex information in an understandable and accessible manner.
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default MD99Assets;
