import React, { useState,  } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Google from "./images/Google.svg";
import Apple from "./images/Apple.svg";
import Github from "./images/Github.svg";
import Windows from "./images/Windows.svg";
import Facebook from "./images/Facebook.svg";
import DividerLine from "./images/divider-line.svg";
import BackBtn from "./images/back-btn.svg";
import toast, { Toaster } from "react-hot-toast";
import { CForm, CFormInput, CButton, CInputGroup, CFormFeedback } from '@coreui/react';
import { motion } from "framer-motion";
import { apiUpdatePwd } from './ApiClient';
import { useParams, useNavigate } from "react-router-dom";

function ResetPassword() {
  const navigate = useNavigate();
  const all_params = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(null);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(null);
  const [validated, setValidated] = useState(false);
  const userId = all_params.user_id;
  const rstCode = all_params.reset_code;



  const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isValidLength = password.length >= 8;
    return hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChar && isValidLength;
  };

  const handlePasswordInputChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    setPasswordValid(validatePassword(value));
    setConfirmPasswordValid(value === confirmPassword && validatePassword(value));
  };

  const handleConfirmPasswordInputChange = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    setConfirmPasswordValid(value === password && validatePassword(value));
  };

  const handleReset = (event) => {
    event.preventDefault();
    setValidated(true);

    if (!passwordValid || !confirmPasswordValid) {
      console.log("validation failed");
      return;
    }

    const post_data = {new_pwd: password, conf_pwd: confirmPassword, req_id: userId, req_code: rstCode };
    apiUpdatePwd(post_data, function(data) {
      toast.success('New Password Created! 👍 You are now logged in!', {
        duration: 4000,
        position: 'top-right',
        style: {
          border: '1px solid #28a745',
          padding: '16px',
          color: '#fff',
          background: '#28a745',
        },
        icon: '✔️',
      });
      // Navigate to dashboard authenticated
      navigate("/dashboard");
    });
  };

  return (
    <motion.div
      className="box"
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.5,
        ease: "easeOut",
        scale: {
          type: "spring",
          damping: 10,
          stiffness: 50,
          restDelta: 0.001
        }
      }}
    >
      <div className="login-container container-fluid d-flex justify-content-center flex-column align-items-center">
        <Toaster />
        <div className="welcome-wrapper d-flex flex-row justify-content-center align-items-center mb-2">
          <img
            className="back-arrow-btn clickable pr-2"
            loading="lazy"
            alt="back-btn"
            src={BackBtn}
            onClick={() => navigate("ConfirmCode")}
          />
          <h1 className="d-flex justify-content-center pricing-title p-0">
            Reset Password
          </h1>
        </div>

        <CForm className="frame-parent mb-2 needs-validation" noValidate validated={validated} onSubmit={handleReset}>
          <div className="frame-group mb-3">
            <div className="input-wrapper">
              <label
                htmlFor="resetPasswordInput"
                className="form-label input-label-text"
              >
                New Password
              </label>
              <CInputGroup className="has-validation">
                <CFormInput
                  type="password"
                  id="resetPasswordInput"
                  name="resetPassword"
                  className={`input-field-login p-3 ${passwordValid === true ? 'is-valid' : passwordValid === false ? 'is-invalid' : ''}`}
                  value={password}
                  onChange={handlePasswordInputChange}
                  required
                  placeholder="Enter your new password"
                />
                <CFormFeedback valid>Looks good!</CFormFeedback>
                <CFormFeedback invalid>
                  Password must be at least 8 characters long, include at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number.
                </CFormFeedback>
              </CInputGroup>
            </div>
          </div>

          <div className="frame-group mb-3">
            <div className="input-wrapper">
              <label
                htmlFor="confirmPasswordInput"
                className="form-label input-label-text"
              >
                Confirm Password
              </label>
              <CInputGroup className="has-validation">
                <CFormInput
                  type="password"
                  id="confirmPasswordInput"
                  name="confirmPassword"
                  className={`input-field-login p-3 ${confirmPasswordValid === true ? 'is-valid' : confirmPasswordValid === false ? 'is-invalid' : ''}`}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordInputChange}
                  required
                  placeholder="Re-enter your new password"
                />
                <CFormFeedback valid>Passwords match!</CFormFeedback>
                <CFormFeedback invalid>
                  Passwords must match and fulfill the password criteria.
                </CFormFeedback>
              </CInputGroup>
            </div>
          </div>

          <div className="group-frame d-flex justify-content-between flex-row w-100">
            <button className="sign-up render-btn px-5 py-2" onClick={() => navigate("/")}>Cancel</button>
            <CButton className="login1 confirm-btn px-5 py-2 mb-2" type="submit">Submit</CButton>
          </div>
        </CForm>
      </div>
    </motion.div>
  );
};

export default ResetPassword;
