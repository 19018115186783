import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import CodeSnippet from '../Prism';
import BrowserSetup from "../images/tutorial-browser-setup.png";
import ConsoleSetup from "../images/tutorial-concept-setup.png";
import { motion } from "framer-motion";
import Prism from 'prismjs';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';


const Level2Tutorial = () => {
  const [openSections, setOpenSections] = useState({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSection = (sectionName) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [sectionName]: !prevOpenSections[sectionName],
    }));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const exampleCode = `
  {
    "user-groups": {
      "core-competency": {
        "communication": 0,
        "leadership": 0,
        "time management": 0,
        "team work": 0,
        "public speaking": 0
      },
      "technical-ability": {
        "JavaScript": 0,
        "Python": 0,
        "Java": 0,
        "C#": 0,
        "Ruby": 0,
        "PHP": 0,
        "Swift": 0,
        "Kotlin": 0,
        "SQL": 0,
        "HTML": 0,
        "CSS": 0,
        "React": 0,
        "Angular": 0,
        "Node.js": 0,
        "Django": 0
      }
    }
  }`
  
  const reactCreateTerminal = `
    npx create-react-app my-app
    cd my-app
  `
  const createReactComponent = `
  import React, { useState, useEffect } from 'react';
import './MyAssets.css'; // Import the CSS file

const MyAssets = () => {
  const [communication, setCommunication] = useState(0);
  const [leadership, setLeadership] = useState(0);
  const [timeManagement, setTimeManagement] = useState(0);
  const [teamWork, setTeamWork] = useState(0);
  const [publicSpeaking, setPublicSpeaking] = useState(0);

  const [javaScript, setJavaScript] = useState(0);
  const [python, setPython] = useState(0);
  const [java, setJava] = useState(0);
  const [cSharp, setCSharp] = useState(0);
  const [ruby, setRuby] = useState(0);
  const [php, setPhp] = useState(0);
  const [swift, setSwift] = useState(0);
  const [kotlin, setKotlin] = useState(0);
  const [sql, setSql] = useState(0);
  const [html, setHtml] = useState(0);
  const [css, setCss] = useState(0);
  const [react, setReact] = useState(0);
  const [angular, setAngular] = useState(0);
  const [nodeJs, setNodeJs] = useState(0);
  const [django, setDjango] = useState(0);

  useEffect(() => {
    fetch('/api.json')
      .then(response => response.json())
      .then(data => {
        const coreComp = data["user-groups"]["core-competency"];
        const techAbility = data["user-groups"]["technical-ability"];
        console.log("JSON data:", data)
        setCommunication(coreComp.communication);
        setLeadership(coreComp.leadership);
        setTimeManagement(coreComp["time management"]);
        setTeamWork(coreComp["team work"]);
        setPublicSpeaking(coreComp["public speaking"]);

        setJavaScript(techAbility.JavaScript);
        setPython(techAbility.Python);
        setJava(techAbility.Java);
        setCSharp(techAbility["C#"]);
        setRuby(techAbility.Ruby);
        setPhp(techAbility.PHP);
        setSwift(techAbility.Swift);
        setKotlin(techAbility.Kotlin);
        setSql(techAbility.SQL);
        setHtml(techAbility.HTML);
        setCss(techAbility.CSS);
        setReact(techAbility.React);
        setAngular(techAbility.Angular);
        setNodeJs(techAbility["Node.js"]);
        setDjango(techAbility.Django);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div>
      <h1>Portfolio Skills Section</h1>
      <h2>Core Competency</h2>
      <div className="competency-container">
        <div className="box">
          <div className="value">{communication}</div>
          <div className="key">Communication</div>
        </div>
        <div className="box">
          <div className="value">{leadership}</div>
          <div className="key">Leadership</div>
        </div>
        <div className="box">
          <div className="value">{timeManagement}</div>
          <div className="key">Time Management</div>
        </div>
        <div className="box">
          <div className="value">{teamWork}</div>
          <div className="key">Team Work</div>
        </div>
        <div className="box">
          <div className="value">{publicSpeaking}</div>
          <div className="key">Public Speaking</div>
        </div>
      </div>

      <h2>Technical Ability</h2>
      <div className="ability-container">
        <div className="box">
          <div className="value">{javaScript}</div>
          <div className="key">JavaScript</div>
        </div>
        <div className="box">
          <div className="value">{python}</div>
          <div className="key">Python</div>
        </div>
        <div className="box">
          <div className="value">{java}</div>
          <div className="key">Java</div>
        </div>
        <div className="box">
          <div className="value">{cSharp}</div>
          <div className="key">C#</div>
        </div>
        <div className="box">
          <div className="value">{ruby}</div>
          <div className="key">Ruby</div>
        </div>
        <div className="box">
          <div className="value">{php}</div>
          <div className="key">PHP</div>
        </div>
        <div className="box">
          <div className="value">{swift}</div>
          <div className="key">Swift</div>
        </div>
        <div className="box">
          <div className="value">{kotlin}</div>
          <div className="key">Kotlin</div>
        </div>
        <div className="box">
          <div className="value">{sql}</div>
          <div className="key">SQL</div>
        </div>
        <div className="box">
          <div className="value">{html}</div>
          <div className="key">HTML</div>
        </div>
        <div className="box">
          <div className="value">{css}</div>
          <div className="key">CSS</div>
        </div>
        <div className="box">
          <div className="value">{react}</div>
          <div className="key">React</div>
        </div>
        <div className="box">
          <div className="value">{angular}</div>
          <div className="key">Angular</div>
        </div>
        <div className="box">
          <div className="value">{nodeJs}</div>
          <div className="key">Node.js</div>
        </div>
        <div className="box">
          <div className="value">{django}</div>
          <div className="key">Django</div>
        </div>
      </div>
    </div>
  );
};

export default MyAssets;
`

  return (
    <motion.div
        className="box"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 1.5,
          ease: "easeOut",
          scale: {
            type: "spring",
            damping: 10,
            stiffness: 50,
            restDelta: 0.001
          }
        }}
      >
    <div className="container-fluid bg-main text-white mt-5">
      <div className="row">
        <div className="hamburger-menu" onClick={toggleSidebar}>
          <div className="hamburger-icon"></div>
          <div className="hamburger-icon"></div>
          <div className="hamburger-icon"></div>
        </div>
        <div className={`col-3 sidebar ${isSidebarOpen ? "open" : ""}`}>
          <div>
            <button
              className="section-btn side-bar-header"
              onClick={() => toggleSection("Quick Start")}
            >
              Getting Started
            </button>
            <div className={`sub-nav side-bar-body ${openSections["Quick Start"] ? "open" : ""}`}>
            <Link to="/documentation/introduction" className="sub-nav-link">Introduction</Link>
            <Link to="/documentation/quick_start" className="sub-nav-link">Quick Start</Link>
            </div>
          </div>
          <div>
            <button
              className="section-btn side-bar-header"
              onClick={() => toggleSection("MD99 Basics")}
            >
              MD99 Basics
            </button>
            <div className={`sub-nav side-bar-body ${openSections["MD99 Basics"] ? "open" : ""}`}>
              <Link to="/documentation/info" className="sub-nav-link">Onboarding</Link>
              <Link to="/documentation/info" className="sub-nav-link">Tour the Interface</Link>
              <Link to="/documentation/info" className="sub-nav-link">Design Your First Asset</Link>
              <Link to="/documentation/info" className="sub-nav-link">Ways to use the MD99 API</Link>
            </div>
          </div>
          <div>
            <button
              className="section-btn side-bar-header"
              onClick={() => toggleSection("Tutorials")}
            >
              Tutorials
            </button>
            <div className={`sub-nav side-bar-body ${openSections["Tutorials"] ? "open" : ""}`}>
              <Link to="/documentation/info" className="sub-nav-link">API Beginners</Link>
              <Link to="/documentation/info" className="sub-nav-link">React/Django Dynamic Routing</Link>
              <Link to="/documentation/info" className="sub-nav-link">React/Django Database</Link>
            </div>
          </div>
          <div>
            <button
              className="section-btn side-bar-header"
              onClick={() => toggleSection("Lifecycle")}
            >
              API Specs
            </button>
            <div className={`sub-nav side-bar-body ${openSections["Lifecycle"] ? "open" : ""}`}>
              <Link to="/documentation/info" className="sub-nav-link">Overview</Link>
              <Link to="/documentation/info" className="sub-nav-link">Connecting to the MD99 API</Link>
              <Link to="/documentation/info" className="sub-nav-link">Authentication Life Cycle</Link>
              <Link to="/documentation/info" className="sub-nav-link">Query Types</Link>
              <Link to="/documentation/info" className="sub-nav-link">Understanding Error Types</Link>
            </div>
          </div>
        </div>

        <div className="col doc-container">
          <div className="col mt-4">
            <h2 className="doc-caps">How to use the MD99 API</h2>
            <h1 className="doc-header">Simple React + API Simulation Tutorial</h1>
            <h3 className="doc-sub-header">
            This is a simple exercise aimed at focusing on the fundamental task needed to use our technology. Every designer and developer comes with a wide range of abilities, so constructing and managing APIs may be new to some, and a simple task to more seasoned developers. By engaging with this exercise, you'll get hands-on experience with the basics of fetching and handling JSON data in a React application. This will lay the groundwork for more advanced API interactions and data management tasks, whether you're just starting out or looking to refine your skills.
            </h3>
          </div>
          
          <div className="px-5">
            <h1 className="doc-sub-header">In this section you will learn how to:</h1>
            <ol className="footer-bg py-4">
              <li className="doc-body-medium p-1">Setup a React project</li>
              <li className="doc-body-medium p-1">Create a JSON file to simulate an API call</li>
              <li className="doc-body-medium p-1">Fetch the JSON data in your React component</li>
              <li className="doc-body-medium p-1">Call the MD99 API</li>
              <li className="doc-body-medium p-1">Render MD99 Data Graphics to the Browser</li>
            </ol>

            <div className="snippet-container">
              <h4 className="doc-header">Step 1: Setting Up Your React Project</h4>
              <p className="doc-sub-header">
              This step involves setting up the initial structure of your React project. Using the npx create-react-app my-app command in your terminal, you'll create a new React application named "my-app." After navigating into the project directory with cd my-app, you'll have all the necessary files and dependencies to start developing your React application.
              </p>
              <p className="doc-body-large">Run the following commands in your Terminal:</p>

              <CodeSnippet code={reactCreateTerminal} language="jsx" />
            </div>

            <div className="snippet-container">
              <h4 className="doc-header">Step 2: Create the API File</h4>
              <p className="doc-sub-header">
              The quickest and easiest way to create JSON data and pass it into a React application for now is to create a static JSON file and fetch it within your React component. Here's how you can do it:
              </p>
              <p className="doc-body-large">Create a JSON file: Create a file named api.json in your public directory (e.g., public/data.json). This file will hold your JSON data.</p>

              <CodeSnippet code={exampleCode} language="json" />
            </div>

            <div className="snippet-container">
              <h4 className="doc-header">Step 3: Create the React Component</h4>
              <p className="doc-sub-header">
                Now, you'll create a new React component named MyAssets in the src directory. This component will be responsible for fetching the JSON data from the api.json file and displaying it on the screen. You'll use React's useState and useEffect hooks to manage and fetch the data, respectively.
              </p>
              <p className="doc-sub-header">
                To display the MyAssets component in your application, import it into the App.js file and include it within the main App component's JSX. This integration will allow the data fetched by MyAssets to be visible when you run your application.
              </p>

              <CodeSnippet code={createReactComponent} language="jsx" />

              <p className="doc-sub-header">
                When you start your react app in the browser, you should be seeing something similar to this:
              </p>
              {/* This is where BrowserSetup will be added */}
              <img src={BrowserSetup} alt="Browser setup" style={{ width: '100%', height: 'auto' }} />

              <p className="doc-sub-header">
                If you open your developer tools and inspect the console, you should see your JSON data has been successfully retrieved and is logging to the console for confirmation.
              </p>
              {/* This is where ConsoleSetup will be added */}
              <img src={ConsoleSetup} alt="Console setup" style={{ width: '100%', height: 'auto' }} />

              <p className="doc-sub-header">
                **Remember we placed a console.log inside of our API call on line 31 of our MyAssets component!
              </p>
            </div>

            <div className="snippet-container">
              <h4 className="doc-header">Call the MD99 API</h4>
              <p className="doc-sub-header"> In this step, you'll learn how to retrieve your API Key from our website, construct an API call to the MD99 API, and dynamically pass the values from your JSON data into a dynamicValue state, which will then be used in a PNG image URL.</p>
    
              <ol className="footer-bg py-4 no-bullets">
                <li className="doc-body-medium">Retrieve User API key</li>
                <li className="doc-body-large p-5">THIS IS WHERE SCREENSHOTS WILL GO</li>
                <li className="doc-body-medium">Create a Function to Fetch Data</li>
                <li className="doc-body-large p-5">THIS IS WHERE THE CODE WILL GO</li>
                <li className="doc-body-medium">Passing Values into dynamicValue State</li>
                <li className="doc-body-large p-5">THIS IS WHERE THE CODE WILL GO</li>
                <li className="doc-body-medium">Use dynamicValue in an Image URL</li>
                <li className="doc-body-large p-5">THIS IS WHERE THE CODE WILL GO</li>
                <li className="doc-body-medium">Construct Image URL</li>
                <li className="doc-body-large p-5">THIS IS WHERE THE CODE WILL GO</li>
              </ol>

              <p className="doc-body-large">This will dynamically update the image based on the value retrieved from the API, demonstrating how to make API calls and utilize the fetched data in your React application.</p>
            </div>

            <div className="snippet-container">
              <h4 className="doc-header">Summary</h4>
              <p className="doc-sub-header">By following these steps, you've successfully retrieved an API Key, constructed an API call to the MD99 API, and dynamically passed the values into your React component. This is a fundamental task that showcases how to integrate external APIs into your applications, a skill that will be crucial as you build more advanced features and functionality.</p>
            </div>

          </div>
        </div>
      </div>
    </div>
    </motion.div>
  );
};

export default Level2Tutorial;
