import React, { useState, useEffect } from "react";
import { phpSessIsLoggedIn, phpSessGet } from './PhpSession.js';
import { apiGetAllAssets } from './ApiClient';
import Introduction from "./Documentation/GettingStarted/Introduction";

const Documentation = ({ setAssetsCount }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authenticated = phpSessIsLoggedIn();
    setIsAuthenticated(authenticated);

    if (authenticated) {
      const token = phpSessGet();
      apiGetAllAssets(token, (assets) => {
        setAssetsCount(assets.length); // Assuming assets is an array
      });
    }
  }, [setAssetsCount]);

  return (
    <div className="row justify-content-center">
      {isAuthenticated && <Introduction />}
    </div>
  );
};

export default Documentation;
