import { apiGetAuthTokenForUser, imgUrlPrefix } from './ApiClient';

export function authTokenBuildUrl (assetName, value, callback) {
    assetName = md99_stripAssetName (assetName);
    // console.log("assetName:", assetName)

	const storedToken = md99_getStoredToken();
	if (storedToken != null) {
		// console.log("Found AuthToken in Cache")
		const publicKey = localStorage.getItem("auth_pubkey");

		const fullUrl =  md99_buildFullUrl(publicKey, storedToken, value, assetName)
        callback(fullUrl);
		return;
	}
		
	apiGetAuthTokenForUser (function (data) {
		const token     = data.token;
		const publicKey = data.public_key;
        localStorage.setItem("auth_token", data.token);
        localStorage.setItem("auth_expire", data.expires);
        localStorage.setItem("auth_pubkey", data.public_key);
		
		const fullUrl =  md99_buildFullUrl(publicKey, token, value, assetName)
        callback(fullUrl);
	});
	// return "";
}
// export function authTokenGetAuthToken () {
// 	apiGetAuthTokenForUser (function (data) {
// 		return data.token;
// 	});
// }

export function authNewPublicKey (newKey) {
	const oldKey = localStorage.getItem("auth_pubkey");
	if (newKey == oldKey) {
		return;
	}
	
	localStorage.setItem ("auth_token",  "");
	localStorage.setItem ("auth_pubkey", "");
}


function md99_buildFullUrl (publicKey, token, value, assetName) 
{
	value = md99_formatValue (value);
    const urlPrefix = imgUrlPrefix();
    const urlSuffix = "get/" + publicKey + "/" + token + "/" + value + "/" + assetName + ".png";
	console.log("urlPrefix + urlSuffix", urlPrefix + urlSuffix);
    return urlPrefix + urlSuffix;
}

function md99_formatValue (value)
{
	var asStr = value.toString();
	asStr = asStr.replaceAll ("-" , "n");
	asStr = asStr.replaceAll ("." , "d");
	return asStr;
}


function md99_stripAssetName(name) {
	name = name.replaceAll (" " , "-");				// replace spaces with dashes
	name = name.toLowerCase();							// change to all lower case
	name = name.replace (/[^-a-z0-9_]/g, "");		// keep only dash, underscore, letters and numbers
	name = name.replace (/\-+/g, '-');				// remove duplicate dashes
	name = name.replace (/^\-+|\-+$/g, "");    	// trim leading and trailing dashes
   return name;
}

function md99_getStoredToken() {
	const expire = localStorage.getItem("auth_expire");
	if (! expire) {
		return null;
	}

	const token = localStorage.getItem("auth_token");
	if (! token) {
		return null;
	}
	if (token.length == 0) {
		return null;
	}
	
	const client = localStorage.getItem("auth_pubkey");
	if (! client) {
		return null;
	}
	if (client.length == 0) {
		return null;
	}

	const now = Math.floor (Date.now() / 1000);
	if (now >= expire) {
		return null;
	}
	return token;
}