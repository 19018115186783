import axios from "axios";
import toast from 'react-hot-toast';
import { phpSessGet, phpSessSet, phpSessLogout } from './PhpSession.js'

// -----------------------------------------------------------------------------------------------

// const BASE_URL = "https://jmc2.lakeshoresoftwareinc.com/";
const BASE_URL = "https://mydesign99.com/";
// const BASE_URL = "/";

// -----------------------------------------------------------------------------------------------

const imgUrlPrefix = () => {
    return "https://mydesign99.com/";
}


function buildURL (route)
{
    var baseUrl = BASE_URL;

    const element = document.getElementById ("use_local_only");
    if (element) {
        if (element.value === "1")
            baseUrl = "/";
    }
    return baseUrl + route;
    
    //return BASE_URL + route;
}

// -----------------------------------------------------------------------------------------------

function apiIsLoggedIn (callback)
{
    const fullURL = buildURL("api/isloggedin");

    getWithNoProcessing (fullURL, function ([success, data, errMsg]) {
        if (success == false) {
console.log ("apiIsLoggedIn failed: " + errMsg);
            callback (false);
            return;
        }
        if (!data.hasOwnProperty("result")) {
console.log ("apiIsLoggedIn failed: No result prop");
            callback (false);
            return;
        }
        if (data.result != "1") {
console.log ("apiIsLoggedIn returned No");
            callback (false);
            return;
        }
console.log ("apiIsLoggedIn return Yes");
        callback (true);
    });
}


const apiLogin = (json_post, callback) => {
    const fullURL = buildURL("api/login");
    
    simplePost (fullURL, json_post, callback);
}

const apiLogout = () => {
    const fullURL = buildURL("api/logout");

    phpSessLogout();
    simpleGet (fullURL, null);
}

// -----------------------------------------------------------------------------------------------

const apiGetAllAssets = (callback) => {
    const fullURL = buildURL("api/assets/list");
    
    simpleGet (fullURL, callback);
}

const apiGetDashboardData = (callback) => {
    const fullURL = buildURL("api/dashboard/get");
    
    simpleGet (fullURL, callback);
}


const apiGetPaymentHistory = (callback) => {
    const fullURL = buildURL("api/payment/history");
    
    simpleGet (fullURL, callback);
}


const apiGetOneAsset = (assetID, callback) => {
    const fullURL = buildURL("api/asset/get/" + assetID);
    
    simpleGet (fullURL,callback);
}

const apiGetMonthlyUsage = (callback) => {
    const fullURL = buildURL("api/monthly/usage");
    
    simpleGet (fullURL, callback);
}

// -----------------------------------------------------------------------------------------------

const apiGetAuthTokenForUser = (callback) => {
	const fullURL = buildURL("api/user/authtoken");
    
	simpleGet(fullURL,callback);
}

// -----------------------------------------------------------------------------------------------
//      User Profile API calls
// -----------------------------------------------------------------------------------------------

function apiUploadProfilePic (fileObj, callback)
{
    const fullURL = buildURL("api/upload/profilepic");

    const formData = new FormData();
    formData.append("filedata", fileObj);
    filePost (fullURL, formData, callback);
}

function buildProfilePicURL (userImageExtension)
{
    return buildURL("api/profile/myimage.") + userImageExtension;
}

function apiChangePassword (oldPassword, newPassword, confirmPwd, callback)
{
    const fullURL = buildURL("api/change/password");
    
    const post_data = {
        old_pwd: oldPassword,
        new_pwd: newPassword,
        confirm: confirmPwd,
    };
  
    simplePost(fullURL, post_data, callback);
}

function apiUpdateUsersName (firstName, lastName, callback)
{
    const fullURL = buildURL("api/update/name");
    
    const post_data = {
        first_name: firstName,
        last_name:  lastName,
    };
    simplePost (fullURL, post_data, callback);
}

function apiSendStripeData (stripeToken, nameOnCard, cardBrand, cardLast4Digits, expireMonth, expireYear, callback)
{
    /*
    const fullURL = buildURL("api/set/stripe/token");
    
    const post_data = {
        pay_token:    stripeToken,
        name_on_card: nameOnCard,
        card_brand:   cardBrand,
        last_4:  cardLast4Digits,
        exp_mn:  expireMonth,
        exp_yr:  expireYear,
    };
    simplePost (fullURL, post_data, callback);
    */

    // do nothing ... this isn't a valid route
}


function apiCreateFirstSubscription (   payMethodID, nameOnCard, cardBrand, cardLast4Digits, 
                                        expireMonth, expireYear, tierNum, callback)
{
    const fullURL = buildURL("api/create/first/subscription");
    
    const post_data = {
        payment_method_id:  payMethodID,
        name_on_card:       nameOnCard,
        card_brand:         cardBrand,
        last_4:             cardLast4Digits,
        exp_mn:             expireMonth,
        exp_yr:             expireYear,
        tier_num:           tierNum,
    };
    simplePost (fullURL, post_data, callback);
}


function apiUpdatePaymentDetails (  payMethodID, nameOnCard, cardBrand, cardLast4Digits, 
                                    expireMonth, expireYear, callback)
{
    const fullURL = buildURL("api/update/payment/details");

    const post_data = {
    payment_method_id:  payMethodID,
    name_on_card:       nameOnCard,
    card_brand:         cardBrand,
    last_4:             cardLast4Digits,
    exp_mn:             expireMonth,
    exp_yr:             expireYear,
    };
    simplePost (fullURL, post_data, callback);
}


function apiChangeTier (tierNum, callback)
{
    const fullURL = buildURL("api/change/tier");

    const post_data = {
        tier_num:  tierNum,
    };
    simplePost (fullURL, post_data, callback);
}


function apiClearCreditCard (password, callback)
{
    const fullURL = buildURL("api/clear/pay/token");
    
    const post_data = {
        pwd: password,
    };
    simplePost (fullURL, post_data, callback);
}

function apiDeleteAccount (password, callback)
{
    const fullURL = buildURL("api/deactivate/account");
    
    const post_data = {
        pwd: password,
    };
    simplePost (fullURL, post_data, callback);
}

// -----------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------

const apiSignUp = (json_post, callback) => {
    const fullURL = buildURL("api/user/add");
    
    simplePost(fullURL, json_post, callback);
}

// -----------------------------------------------------------------------------------------------

const apiRequestPwdReset = (json_post, callback) => {
    const fullURL = buildURL("api/pwdreset/request");
    
    simplePost(fullURL, json_post, callback);
}

const apiUpdatePwd = (json_post, callback) => {
    const fullURL = buildURL("api/pwdreset/update");
    
    simplePost(fullURL, json_post, callback);
}

// -----------------------------------------------------------------------------------------------
//      Assets
// -----------------------------------------------------------------------------------------------

const apiAddAsset = (json_post, callback) => {
    const fullURL = buildURL("api/asset/add");
    
    simplePost(fullURL, json_post, callback);
}

const apiSaveOneAsset = (json_post, callback) => {
    const fullURL = buildURL("api/asset/set");

    simplePost(fullURL, json_post, callback);
}

function apiUpdateAssetName (assetID, assetName, callback)
{
    const fullURL = buildURL("api/asset/setname");
    
    const post_data = {
        asset_id:   assetID,
        asset_name: assetName,
    };
    simplePost (fullURL, post_data, callback);
}

const apiDeleteAsset = (assetId, password, callback) => {
    const fullURL = buildURL("api/asset/delete");
    const postData = {
        asset_id: assetId,
        pwd: password,
        lsi_session_id: phpSessGet()
    };
    simplePost(fullURL, postData, callback);
}

// -----------------------------------------------------------------------------------------------

const getWithNoProcessing = (fullURL, callback) => 
{
    const sessionID = phpSessGet();
    fullURL = fullURL + "?lsi_session_id=" + sessionID + "&ts=" + Date.now()
    // console.log("fullURL:", fullURL)
    axios.get(fullURL)
        .then((response) => {
            // console.log("Got good response - Ready to Process")
            callback (getResponseData (response));
        })
        .catch((error) => {
            // console.log("Unknown Error in Axios GET")
            callback ( [false, null, 'Unknown Error in Axios GET.'] );
        });
}

const simpleGet = (fullURL, callback) => {
    const sessionID = phpSessGet();
    fullURL = fullURL + "?lsi_session_id=" + sessionID + "&ts=" + Date.now()
    // console.log("fullURL:", fullURL)
    axios.get(fullURL)
        .then((response) => {
            // console.log("Got good response - Ready to Process")
            processGoodResponse(response, callback);
        })
        .catch((error) => {
            // console.log("Unknown Error in Axios GET")
            toast.error("Invalid Response(GET) ", error);
            return;
        });
}

// -----------------------------------------------------------------------------------------------

const simplePost = (fullURL, post_data, callback) => {
    const sessionID = phpSessGet();
    post_data.lsi_session_id = sessionID;

    // console.log("POST data:", post_data);
    // console.log("calling:", fullURL);

//  axios.post(fullURL, post_data, {withCredentials:false})
    axios.post(fullURL, post_data)
        .then((response) => {
            // console.log("POST response:", response);
            processGoodResponse(response, callback);

        })
        .catch((error) => {
            // console.log("post failed:", fullURL);
            // console.error("POST error:", error);
            if (error && error.response) {
                toast.error("API call failed: " + error.response.data.message);
            } else {
                toast.error("API call failed: Unknown error(TOP LEVEL)");
            }
        });
};

// -----------------------------------------------------------------------------------------------

function filePost (fullURL, formData, callback)
{
    const post_data = {
        lsi_session_id: phpSessGet()
    };
    // formData.append ("post_data", post_data);

    formData.append ("json_post", JSON.stringify(post_data));

    axios.post(fullURL, formData, {headers: {"Content-Type": "multipart/form-data"}})
        .then((response) => {
            // console.log("response:", response);
            // console.log("POST response:", response);
            processGoodResponse(response, callback);
    })
        .catch((error) => {
            // console.log("post failed:", fullURL);
            // console.error("POST error:", error);
            if (error && error.response) {
                toast.error("API call failed: " + error.response.data.message);
            } else {
                toast.error("API call failed: Unknown error(TOP LEVEL)");
            }
    });
};

// -----------------------------------------------------------------------------------------------

const processGoodResponse = (response, callback) => 
{
    if (callback == null) {
        let [success, , errMsg] = getResponseData (response, true);
        if (! success) {
            toast.error (errMsg);
        }
        return;
    }
    else {
        let [success, data, errMsg] = getResponseData (response);
        if (! success) {
            toast.error (errMsg);
            return
        }
        callback (data);
        return;
    }
};

function getResponseData (response, successTestOnly = false)
{
    //console.log("response:", response)
    if (!response.hasOwnProperty("data")) {
        return [false, null, 'Invalid Response Format(1).'];
    }

    // console.log("Success - Has Data Property");
    if (!response.data.hasOwnProperty("is_success")) {
        return [false, null, 'Invalid Response Format(2).'];
    }

    // console.log("Success - Has Is Success Property");
    if (response.data.is_success != "1") {
        if (!response.data.hasOwnProperty("err_msg")) {
            return [false, null, 'Invalid Response Format(3).'];
        } 
        else {
            return [false, null, response.data.err_msg];
        }
    }

    if (successTestOnly) {
        return [true, null, ''];
    }

    // console.log("Success is 1");
    if (!response.data.hasOwnProperty("data")) {
        return [false, null, 'Invalid Response Format(4).'];
    }

    if (response.data.data.hasOwnProperty("lsi_session_id")) {
        phpSessSet(response.data.data.lsi_session_id);
    }

    // console.log("Success - Has Inner Data Property");
    return [true, response.data.data, ''];
}
    
// -----------------------------------------------------------------------------------------------

export {apiLogin, apiLogout, apiIsLoggedIn, apiGetAllAssets, apiGetOneAsset, apiSaveOneAsset, apiGetMonthlyUsage,
        apiAddAsset, apiDeleteAsset, apiSignUp, apiRequestPwdReset, apiUpdatePwd, 
        apiGetDashboardData, apiGetPaymentHistory, apiGetAuthTokenForUser, imgUrlPrefix, apiUploadProfilePic, 
        buildProfilePicURL, apiChangePassword, apiUpdateUsersName, 
        apiCreateFirstSubscription, apiSendStripeData, apiUpdatePaymentDetails, apiChangeTier, apiClearCreditCard, 
        apiDeleteAccount, apiUpdateAssetName, buildURL };
