import { buildURL } from './ApiClient';

	const LOCSTOR_KEY_SessionID			= "sessID";
	const LOCSTOR_KEY_FirstName			= "first_name";
	const LOCSTOR_KEY_LastName				= "last_name";
	const LOCSTOR_KEY_AssetCount			= "asset_count";
	const LOCSTOR_VAL_SessID_LoggedOut	= "dummy";


export function phpSessGet()
{
	let sessID = localStorage.getItem (LOCSTOR_KEY_SessionID);
	if (!sessID) {
	  sessID = LOCSTOR_VAL_SessID_LoggedOut;
	  phpSessSet(sessID);
	}
	return sessID;
}
  
export function phpSessSet(sessID) 
{
	localStorage.setItem (LOCSTOR_KEY_SessionID, sessID);
}
  
export function phpSessLogout() 
{
	localStorage.setItem (LOCSTOR_KEY_SessionID, LOCSTOR_VAL_SessID_LoggedOut);
	localStorage.setItem (LOCSTOR_KEY_FirstName, "");
	localStorage.setItem (LOCSTOR_KEY_LastName, "");
	localStorage.setItem ("tier_text", "");
	localStorage.setItem ("profile_url", "");
	localStorage.setItem ("public_key", "");
	localStorage.setItem ("secret_key", "");
	localStorage.setItem (LOCSTOR_KEY_AssetCount, 0);
}
  
export function phpSessIsLoggedIn() 
{
	const sessID = phpSessGet();
	return sessID !== LOCSTOR_VAL_SessID_LoggedOut;
}

export function phpSessNameSet (firstName, lastName)
{
	localStorage.setItem (LOCSTOR_KEY_FirstName, firstName);
	localStorage.setItem (LOCSTOR_KEY_LastName,  lastName);
}

export function phpSessFirstNameGet ()
{
	let firstName = localStorage.getItem (LOCSTOR_KEY_FirstName);
	if (!firstName) {
	  return "unknown";
	}
	return firstName;
}
  
export function phpSessLastNameGet()
{
	let lastName = localStorage.getItem (LOCSTOR_KEY_LastName);
	if (!lastName) {
	  return "unknown";
	}
	return lastName;
}

export function phpSessFullNameGet() 
{
	let firstName = localStorage.getItem (LOCSTOR_KEY_FirstName);
	let lastName  = localStorage.getItem (LOCSTOR_KEY_LastName);
	if (!firstName) {
		firstName = "";
	}else {
		firstName += " "; 
	}
	if (!lastName) {
		lastName = "unknown";
	  }
	return firstName + " " + lastName;
}

export function phpSessTierSet(tier_text) 
{
	localStorage.setItem("tier_text", tier_text);
}

export function phpSessTierGet() {
	let tier_text = localStorage.getItem("tier_text");
	if (!tier_text) {
	  return "Free";
	}
	return tier_text;
}
  
export function phpSessProfileUrlSet (profileUrl, profileVersion) 
{
	localStorage.setItem("profile_url", profileUrl);
	localStorage.setItem("profile_ver", profileVersion);
}

export function phpSessProfileUrlGet()
{
	let profileUrl		 = localStorage.getItem("profile_url");
	let profileVersion = localStorage.getItem("profile_ver");

	if (profileUrl == null  ||  profileUrl.length === 0)
//		return 'https://ui-avatars.com/api/?name=User&background=random&rounded=true';
		return 'https://via.placeholder.com/200';

	//return "https://mydesign99.com" + profileUrl + "?lsi_session_id=" + phpSessGet();
	return buildURL (profileUrl + "?v=" + profileVersion + "&lsi_session_id=" + phpSessGet());
}

  
export function phpSessPKeySet(public_key) {
	localStorage.setItem("public_key", public_key);
}

export function phpSessPKeyGet()
{
	let public_key = localStorage.getItem("public_key");
	if (!public_key) {
	  return "???";
	}
	return public_key;
}
  
export function phpSessSKeySet(secret_key) {
	localStorage.setItem("secret_key", secret_key);
}

export function phpSessSKeyGet() {
	let secret_key = localStorage.getItem("secret_key");
	if (!secret_key) {
	  return "???";
	}
	return secret_key;
}
  
 
export function phpSessAssetCountSet (count)
{
	localStorage.setItem (LOCSTOR_KEY_AssetCount, count);
}

export function phpSessAssetCountGet ()
{
	let count = localStorage.getItem (LOCSTOR_KEY_AssetCount);
	if (!count) {
	  return 0;
	}
	return count;
}
  
