import React, { useEffect, useRef } from "react";
import { formatStyles, formatClasses } from "./EditorUtils";

function DelayedInput ({ value, id, cNames=null, styles=null, onChange })
{
   const outerValue  = useRef(value);
   const innerValue  = useRef(value);
   const timerObj    = useRef(null);

   styles = formatStyles  (styles);
   cNames = formatClasses (cNames);

   function innerOnChange (e)
   {
      innerValue.current = e.target.value;

      if (timerObj.current !== null) {
         clearTimeout (timerObj.current);
         timerObj.current = null;
      }
         
      timerObj.current = setTimeout(() => {
         if (innerValue.current !== outerValue.current  &&  isValidCutoff (innerValue.current)) {
            onChange (innerValue.current);
            outerValue.current = innerValue.current;
         }
      }, 2000);
   }

   function innerOnBlur (e)
   {
      innerValue.current = e.target.value;

      if (timerObj.current !== null) {
         clearTimeout (timerObj.current);
         timerObj.current = null;
      }

      if (innerValue.current !== outerValue.current  &&  isValidCutoff (innerValue.current)) {
         onChange (innerValue.current);
         outerValue.current = innerValue.current;
      }
   }
   

   function isValidCutoff (val)
   {
      val = parseInt (val);
      if (isNaN(val))
         return false;

      if (val <= 0  ||  val >= 100)
         return false;

      return true;
   }   

   useEffect(() => {
      return () => clearTimeout (timerObj.current)
   }, 
   [])
      
   
   return (
      <input
         type="text"
         id={id}
         defaultValue={value}
         className={cNames}
         style={styles}
         onChange={innerOnChange}
         onBlur={innerOnBlur}
      />
   );
}

export default DelayedInput;
