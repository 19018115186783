import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
//import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { apiLogout, apiIsLoggedIn } from './ApiClient';
import { phpSessIsLoggedIn, phpSessLogout, phpSessFullNameGet, phpSessProfileUrlGet, phpSessAssetCountGet } from './PhpSession.js';
import toast from "react-hot-toast";

import MD99Logo from "./images/type-1000-xl.png";
import { 
  CNavbar, 
  CContainer, 
  CNavbarBrand, 
  CNavbarToggler, 
  CCollapse, 
  CNavbarNav, 
  CNavItem, 
  //CNavLink, 
  CBadge,
  CAvatar,
  CTooltip
} from '@coreui/react';
//import CIcon from '@coreui/icons-react';
//import { cilUser } from '@coreui/icons';

// Fetch user's profile image or use a placeholder if not available
//const placeholderImage = phpSessProfileUrlGet();

    const MAX_TIME_AWAY_MS   = 10 * 60 * 1000;    // 10 minutes
//  const MAX_TIME_AWAY_MS   = 30 * 1000;         // 30 seconds


//function TopNav({ assetsCount, resetAssetsCount }) {
function TopNav ({ forceRedrawCallback }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
//const [userName, setUserName] = useState('');

  // --------------------------------------------------------------

  useEffect(() => 
  {
    const loggedIn = phpSessIsLoggedIn();
    setUserLoggedIn(loggedIn);
    //if (loggedIn) {
      //setUserName (phpSessFullNameGet ());
    //}
    
    window.addEventListener("blur", lostFocus);
    window.addEventListener("focus", gotFocus);

    // this tests to see if we are still logged in from a previous session
    processLoggedInStatus ();

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("blur", lostFocus);
      window.removeEventListener("focus", gotFocus);
    };
  }, 
  []);   // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const loggedIn = phpSessIsLoggedIn();
    setUserLoggedIn(loggedIn);
    //if (loggedIn) {
      //setUserName (phpSessFullNameGet());
    //}
  }, 
  [location]);

  // --------------------------------------------------------------

  const handleNavCollapse = () => setVisible(!visible);


  function handleGotoLogin ()
  {
    //setUserLoggedIn(true);
    navigate("/"); // Navigate to home page
  }

  const handleSignup = () => {
    navigate("/", { state: { showSignup: true } }); // Navigate to home page with state to show Signup
  };

  const handleLogout = () => {
    apiLogout(); // Ensure the server logout is called
    internalLogout ();
    //toast.success('You have successfully logged out', { duration: 3000 });
  };

  function internalLogout ()
  {
    phpSessLogout();          // Clear the session locally
    setUserLoggedIn (false);  // Update the login status
    //resetAssetsCount();       // Reset the assets count
    //setUserName('');          // Clear the user name
    navigate("/");
    forceRedrawCallback ();
  }

  const closeMenu = () => setVisible(false);

  // --------------------------------------------------------------
  //    Function to handle when the window loses focus
  function lostFocus ()
  {
    const startTime = new Date().getTime();
    localStorage.setItem("lost_time", startTime);
  }

  //    Function to handle when the window gains focus
  function gotFocus ()
  {
    if (getTimeAway() <= MAX_TIME_AWAY_MS)
      return;

    processLoggedInStatus ();
  }

  function getTimeAway ()
  {
    const startTime = localStorage.getItem("lost_time");
    if (!startTime) {
      return 0;
    }

    const endTime = new Date();
    return endTime.getTime() - startTime;
  }

  function processLoggedInStatus ()
  {
    if (! phpSessIsLoggedIn ())
      return;

    apiIsLoggedIn (function(isLoggedIn) {
      if (! isLoggedIn) {
        internalLogout ();      
        toast ('Your session has expired. Please login again.', { duration: 4000, position: 'top-center'});
      }
    });
  }

  // --------------------------------------------------------------

  return (
    <CNavbar expand="lg" className="top-nav pt-4">
      <CContainer fluid>
        <CNavbarBrand>
          <Link to="/" onClick={closeMenu}>
            <img src={MD99Logo} alt="Color Logo Icon" className="logo-nav pl-3" />
          </Link>
        </CNavbarBrand>
        <CNavbarToggler
          aria-label="Toggle navigation"
          aria-expanded={visible}
          onClick={handleNavCollapse}
        />
        <CCollapse className="navbar-collapse" visible={visible}>
          <CNavbarNav className="mx-auto">
            <CNavItem>
              <Link className="nav-link top-nav-text py-2 px-5" to="/" onClick={closeMenu}>
                Home
              </Link>
            </CNavItem>
            <CNavItem className="position-relative">
              <Link className="nav-link top-nav-text py-2 px-5" to="/dashboard" onClick={closeMenu}>
                My Assets
                <CBadge shape="rounded-pill" className="badge-custom position-absolute top-0 start-35">
{/*                  {assetsCount} */}
                  {phpSessAssetCountGet ()}
                </CBadge>
              </Link>
            </CNavItem>
            
            <CNavItem>
              <Link className="nav-link top-nav-text py-2 px-5" to="/documentation/introduction" onClick={closeMenu}>
                Documentation
              </Link>
            </CNavItem>

            <CNavItem>
              <Link className="nav-link top-nav-text py-2 px-5" to="/info/pricing" onClick={closeMenu}>
                Pricing
              </Link>
            </CNavItem>
          </CNavbarNav>
          {userLoggedIn ? (
            <div className="d-flex align-items-center">
{/*           <span className="top-nav-text mr-2 p-0">{userName}</span> */}
              <span className="top-nav-text mr-2 p-0">{phpSessFullNameGet ()}</span>
              <CTooltip content="Profile" placement="bottom">
                <CAvatar
/*                  src={placeholderImage}*/
                  src={phpSessProfileUrlGet()}
                  size="md"
                  status="success"
                  onClick={() => navigate("/info/profile")}
                  className="mr-3 avatar-img"
                  style={{ cursor: 'pointer' }}
                />
              </CTooltip>
              <button className="btn login-btn-top ml-auto pr-5" onClick={() => { handleLogout(); closeMenu(); }}>
                Logout
              </button>
            </div>
          ) : (
            <div className="d-flex">
              <button className="btn signup-btn-top ml-auto" onClick={() => { handleSignup(); closeMenu(); }}>
                Signup
              </button>
              <button className="btn login-btn-top py-2 pr-5" onClick={() => { handleGotoLogin(); closeMenu(); }}>
                Login
              </button>
            </div>
          )}
        </CCollapse>
      </CContainer>
    </CNavbar>
  );
}

export default TopNav;
