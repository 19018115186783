import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import { FaChevronDown, FaChevronRight } from "react-icons/fa"; // Importing arrow icons from react-icons

const Sidebar = () => {
  const [openSections, setOpenSections] = useState({
    "Quick Start": true,
    "MD99 Basics": true,
    "Tutorials": true,
    "Lifecycle": true,
  });

  const toggleSection = (sectionName) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [sectionName]: !prevOpenSections[sectionName],
    }));
  };

  return (
    <div className="col-3 sidebar open mr-4">
      <div className="div">
      <div>
        <button
          className="section-btn side-bar-header"
          onClick={() => toggleSection("Quick Start")}
        >
          {openSections["Quick Start"] ? <FaChevronDown className="arrow-icon mr-3" /> : <FaChevronRight className="arrow-icon mr-3" />}
          Getting Started
        </button>
        <div className={`sub-nav side-bar-body ${openSections["Quick Start"] ? "open" : ""}`}>
        <Link to="/documentation/introduction" className="sub-nav-link custom-link">Up & Running</Link>
        <Link to="/documentation/md99_assets" className="sub-nav-link custom-link">MD99 Assets</Link>
        <Link to="/documentation/quick_start" className="sub-nav-link custom-link">User Testing</Link>
        </div>
      </div>

      <div>
        <button
          className="section-btn side-bar-header"
          onClick={() => toggleSection("Tutorials")}
        >
          {openSections["Tutorials"] ? <FaChevronDown className="arrow-icon mr-3" /> : <FaChevronRight className="arrow-icon mr-3" />}
          Tutorials
        </button>
        <div className={`sub-nav side-bar-body ${openSections["Tutorials"] ? "open" : ""}`}>
        <Link to="/documentation/api_beginners" className="sub-nav-link custom-link">Your First Design</Link>
          <Link to="/documentation/api_beginners" className="sub-nav-link custom-link">API Beginners</Link>
          <Link to="/documentation/dynamic_routing_node" className="sub-nav-link custom-link">NodeJS Integration</Link>
          <Link to="/documentation/dynamic_routing" className="sub-nav-link custom-link">Django Integration</Link>
          <Link to="/documentation/database" className="sub-nav-link custom-link">Database Integration</Link>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Sidebar;
