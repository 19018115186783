import React, { useState, useEffect } from 'react';
import { phpSessIsLoggedIn, phpSessFullNameGet } from './PhpSession.js';
import CopyCode from './images/copy-code.png';
import toast from 'react-hot-toast';
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { apiUploadProfilePic, buildProfilePicURL, apiChangePassword, apiSendStripeData, apiClearCreditCard, apiDeleteAccount, apiUpdateUsersName } from './ApiClient';
import { Modal } from "react-responsive-modal";
import FileUploader from './FileUploader.js';
import { phpSessTierGet, phpSessProfileUrlGet, phpSessProfileUrlSet, phpSessPKeyGet, phpSessSKeyGet, phpSessNameSet } from "./PhpSession";
//import UploadPic from "./images/upload-pic-box.png";
import DroppableDiv from './DroppableDiv.js';

//const placeholderImage = 'https://via.placeholder.com/200';

function Profile ({ forceRedrawCallback })
{
  const [userName, setUserName] = useState('');
  const [publicKey, setPublicKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
//const [profilePic, setProfilePic] = useState (placeholderImage);
  const [profilePic, setProfilePic] = useState (phpSessProfileUrlGet ());  
  const [openModal, setOpenModal] = useState({});
  const [cards, setCards] = useState( [{ last4: '1234', brand: 'Visa' }] );
  const [selectedCard, setSelectedCard] = useState(null);
  const [billingTab, setBillingTab] = useState('info');

  useEffect(() => {
    const loggedIn = phpSessIsLoggedIn();
    if (loggedIn) {
      const fullName = phpSessFullNameGet();
      setUserName(fullName);

      // Fetch and set Public and Secret keys
      setPublicKey("YOUR_PUBLIC_KEY");
      setSecretKey("YOUR_SECRET_KEY");
    }
  }, []);

  const handlePublicCopyClick = () => {
    navigator.clipboard.writeText(publicKey);
    toast.success("✓ Your PUBLIC KEY has been copied to clipboard!", {
      position: "bottom-right",
      duration: 5000,
      style: {
        background: '#4caf50',
        color: '#fff',
        fontSize: '16px',
      },
    });
  };

  const handleSecretCopyClick = () => {
    navigator.clipboard.writeText(secretKey);
    toast.success("✓ Your SECRET KEY has been copied to clipboard!", {
      position: "bottom-right",
      duration: 5000,
      style: {
        background: '#4caf50',
        color: '#fff',
        fontSize: '16px',
      },
    });
  };

  function handleProfileImageSelected (event)
  {
    apiUploadProfilePic (event.target.files[0], 
      function (response) 
      {
        updateProfilePicUrl (response.profile_pic_url);
      }
    );
  }

  function handleChangePassword (oldPassword, newPassword, confirmPwd)
  {
    apiChangePassword (oldPassword, newPassword, confirmPwd, 
      function (response) 
      {
        toast.success("Password updated successfully!");
        setOpenModal ({ ...openModal, changePassword: false });
      }
    );
  }

  function handleUpdateUserName (firstName, lastName)
  {
    apiUpdateUsersName (firstName, lastName, 
      function (response)
      {
        toast.success("Username updated successfully!");
        setOpenModal({ ...openModal, changeUsername: false });
console.log ("Got response. New Name: " + firstName + " " + lastName);
        phpSessNameSet (firstName, lastName);
        setUserName (phpSessFullNameGet());
        forceRedrawCallback ();
    });
  }

  function handleSendStripeData (stripeToken, nameOnCard, cardBrand, cardLast4Digits, expireMonth, expireYear)
  {
    apiSendStripeData (stripeToken, nameOnCard, cardBrand, cardLast4Digits, expireMonth, expireYear, 
      function (response)
      {
        toast.success("Card added successfully!");
        setCards([...cards, { last4: cardLast4Digits, brand: cardBrand }]);
        setOpenModal({ ...openModal, payments: false });
      }
    );
  }

  const handleClearCreditCard = (password, last4) => {
    apiClearCreditCard(password, (response) => {
      toast.success("Card deleted successfully!");
      setCards(cards.filter(card => card.last4 !== last4));
      setOpenModal({ ...openModal, subscriptions: false });
    });
  };

  const handleDeleteAccount = (password) => {
    apiDeleteAccount(password, (response) => {
      toast.success("Account deleted successfully!");
      setOpenModal({ ...openModal, deleteAccount: false });
    });
  };

  const handleOpenModal = (modalType) => {
    setOpenModal({ ...openModal, [modalType]: true });
  };

  const handleCloseModal = (modalType) => {
    setOpenModal({ ...openModal, [modalType]: false });
  };

  const isAnyModalOpen = Object.values(openModal).some(isOpen => isOpen);

  const selectCard = (last4) => {
    setSelectedCard(last4);
    toast.success(`Card ending in ${last4} selected for payments!`);
  };

  function dropHandler (fileObj) 
  {
    if (fileObj == null)
      return;

    apiUploadProfilePic (fileObj,
      function (response)
      {
        updateProfilePicUrl (response.profile_pic_url, response.profile_pic_ver);
      }
    );
  }

  function updateProfilePicUrl (fullUrl, profileVersion) 
  {
    phpSessProfileUrlSet (fullUrl, profileVersion);
    setProfilePic (phpSessProfileUrlGet ());
    forceRedrawCallback ();
}


  return (
    <div className={`container-fluid d-flex justify-content-center mt-4 pt-4 ${isAnyModalOpen ? 'blur-content' : ''}`}>
      <div className="row w-100 d-flex justify-content-center">
        {/* Public and Secret Key Section */}
        <div className="col-10 d-flex flex-row justify-content-center mb-4">
          <div className="d-flex flex-wrap api-key justify-content-around mx-4">
            <div className="d-flex align-items-center">
              <div className="component-title">Public Key:</div>
              <div className="mx-2 api-key-container api-key-text">{phpSessPKeyGet()}</div>
              <img
                src={CopyCode}
                className="copy-code-icon"
                alt="Copy"
                style={{ cursor: 'pointer' }}
                onClick={handlePublicCopyClick}
              />
            </div>
          </div>

          <div className="d-flex flex-wrap api-key justify-content-around">
            <div className="d-flex align-items-center">
              <div className="component-title">Secret Key:</div>
              <div className="mx-2 api-key-container api-key-text">{phpSessSKeyGet()}</div>
              <img
                src={CopyCode}
                className="copy-code-icon"
                alt="Copy"
                style={{ cursor: 'pointer' }}
                onClick={handleSecretCopyClick}
              />
            </div>
          </div>
        </div>
  
        <div className="d-flex justify-content-center w-100">
          {/* Left Column */}
          <div className="col-6 d-flex flex-column justify-content-center align-items-center text-center ml-3 p-4 gradient-container">
            <div className="">
              <img
                src={profilePic}
                alt="User Avatar"
                className="rounded-circle mb-3 profile-avatar"
              />
              {/* <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="profilePicInput"
                onChange={handleProfileImageSelected}
              />
              <button
                className="profile-pic-upload-btn"
                onClick={() => document.getElementById("profilePicInput").click()}
              >
                &#128247;
              </button> */}
              <p className="doc-header text-center">{userName}</p>
              <p className="doc-sub-header text-center">Member Status: {phpSessTierGet()}</p>
              <p className="doc-sub-header text-center">Member Since: 00/00/00</p>
              <p className="doc-sub-header text-center">Email: user@example.com</p>
              <div className="d-flex justify-content-center mt-3">
                <a href="#" className="mx-2 text-decoration-none">
                  <img src="https://via.placeholder.com/24" alt="Social Icon" className="social-icon" />
                </a>
                <a href="#" className="mx-2 text-decoration-none">
                  <img src="https://via.placeholder.com/24" alt="Social Icon" className="social-icon" />
                </a>
                <a href="#" className="mx-2 text-decoration-none">
                  <img src="https://via.placeholder.com/24" alt="Social Icon" className="social-icon" />
                </a>
              </div>
            </div>
          </div>
  
          {/* Right Column */}
          <div className="col-8 d-flex flex-column justify-content-center p-4 gradient-container-profile-2 ml-3">
            <div className="">
              <p className="toolbar-header gradient-toolbar mt-2 py-2 pl-3">
                Account Preferences
              </p>
              
              {/* Profile Pic */}
              <div className="d-flex justify-content-between align-items-center my-2 mb-3 pb-3 profile-divider-line" style={{ padding: '0 20px' }}>
                <label className="component-title" style={{ flex: '1 0 auto', marginRight: '20px' }}>Profile Pic</label>

                {/* <label htmlFor="upload-input" style={{ cursor: "pointer", flex: '0 0 auto', padding: '10px 20px' }}>
                  <input 
                    id="upload-input" 
                    type="file" 
                    style={{ display: 'none' }} 
                    onChange={handleProfileImageSelected} 
                  /> */}
                  <DroppableDiv 
                    content="Drop File Here" 
                    allClasses="droppable-div" 
                    activeClass="droppable-active" 
                    inactiveClass="droppable-inactive" 
                    callback={dropHandler}
                  />
                {/* </label> */}
              </div>

{/* Subscriptions */}
<div className="d-flex justify-content-between align-items-center my-2 mb-3 pb-3 profile-divider-line" style={{ padding: '0 20px' }}>
                <label className="component-title" style={{ flex: '1 0 auto', marginRight: '20px' }}>Subscriptions</label>
                <button
                  className="profile-pref-btn p-1"
                  style={{ flex: '0 0 auto', padding: '10px 20px' }}
                  onClick={() => handleOpenModal('subscriptions')}
                >
                  View
                </button>
              </div>
  
              {/* Payments */}
              <div className="d-flex justify-content-between align-items-center my-2 mb-3 pb-3 profile-divider-line" style={{ padding: '0 20px' }}>
                <label className="component-title" style={{ flex: '1 0 auto', marginRight: '20px' }}>Payments</label>
                <button
                  className="profile-pref-btn p-1"
                  style={{ flex: '0 0 auto', padding: '10px 20px' }}
                  onClick={() => handleOpenModal('payments')}
                >
                  Edit
                </button>
              </div>
  
              {/* Billing */}
              <div className="d-flex justify-content-between align-items-center my-2 mb-3 pb-3 profile-divider-line" style={{ padding: '0 20px' }}>
                <label className="component-title" style={{ flex: '1 0 auto', marginRight: '20px' }}>Billing</label>
                <button
                  className="profile-pref-btn p-1"
                  style={{ flex: '0 0 auto', padding: '10px 20px' }}
                  onClick={() => handleOpenModal('billing')}
                >
                  View
                </button>
              </div>
  
              {/* Change Password */}
              <div className="d-flex justify-content-between align-items-center my-2 mb-3 pb-3 profile-divider-line" style={{ padding: '0 20px' }}>
                <label className="component-title" style={{ flex: '1 0 auto', marginRight: '20px' }}>Change Password</label>
                <button
                  className="profile-pref-btn p-1"
                  style={{ flex: '0 0 auto', padding: '10px 20px' }}
                  onClick={() => handleOpenModal('changePassword')}
                >
                  Update
                </button>
              </div>
  
              {/* Change Username */}
              <div className="d-flex justify-content-between align-items-center my-2 mb-3 pb-3 profile-divider-line" style={{ padding: '0 20px' }}>
                <label className="component-title" style={{ flex: '1 0 auto', marginRight: '20px' }}>Change Username</label>
                <button
                  className="profile-pref-btn p-1"
                  style={{ flex: '0 0 auto', padding: '10px 20px' }}
                  onClick={() => handleOpenModal('changeUsername')}
                >
                  Update
                </button>
              </div>
  
              {/* Delete Account */}
              <div className="d-flex justify-content-between align-items-center my-2 mb-3 pb-3 profile-divider-line" style={{ padding: '0 20px' }}>
                <label className="component-title" style={{ flex: '1 0 auto', marginRight: '20px' }}>Delete Account</label>
                <button
                  className="profile-pref-btn p-1"
                  style={{ flex: '0 0 auto', padding: '10px 20px' }}
                  onClick={() => handleOpenModal('deleteAccount')}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      {/* Modals */}
      {/* Upload Profile Picture Modal */}
      <Modal
        open={openModal.uploadProfilePic}
        onClose={() => handleCloseModal('uploadProfilePic')}
        center
        classNames={{
          modal: 'custom-modal',
          overlay: 'custom-overlay',
        }}
      >
        <div>
          <FileUploader 
            btnText="Upload Profile Picture" 
            classNamesStr=""
            stylesList={{}} 
            onChange={handleProfileImageSelected}
          />
        </div>
      </Modal>
  
      {/* Change Password Modal */}
      <Modal
        open={openModal.changePassword}
        onClose={() => handleCloseModal('changePassword')}
        center
        classNames={{
          modal: 'custom-modal',
          overlay: 'custom-overlay',
        }}
      >
        <div className="d-flex flex-column">
          <h2 className="toolbar-header gradient-toolbar px-2 py-2">Change Password</h2>
          <input className="my-3 input-field-asset-name" type="password" placeholder="Old Password" id="oldPassword" />
          <input className="mb-3 input-field-asset-name" type="password" placeholder="New Password" id="newPassword" />
          <input className="mb-3 input-field-asset-name" type="password" placeholder="Confirm Password" id="confirmPwd" />
          <button
            className="confirm-btn"
            onClick={() =>
              handleChangePassword(
                document.getElementById("oldPassword").value,
                document.getElementById("newPassword").value,
                document.getElementById("confirmPwd").value
              )
            }
          >
            Change Password
          </button>
        </div>
      </Modal>
  
      {/* Change Username Modal */}
      <Modal
        open={openModal.changeUsername}
        onClose={() => handleCloseModal('changeUsername')}
        center
        classNames={{
          modal: 'custom-modal',
          overlay: 'custom-overlay',
        }}
      >
        <div className="d-flex flex-column p-4">
          <h2 className="toolbar-header gradient-toolbar px-4 py-2">Change Username</h2>
          <input className="my-3 input-field-asset-name" type="text" id="first_name" placeholder="First Name" />
          <input className="my-3 input-field-asset-name" type="text" id="last_name" placeholder="Last Name" />
          <button className="confirm-btn" onClick={() => handleUpdateUserName(
                document.getElementById("first_name").value,
                document.getElementById("last_name").value
              )}
          >
            Update Username
          </button>
        </div>
      </Modal>
  
      {/* Subscriptions Modal */}
      <Modal
        open={openModal.subscriptions}
        onClose={() => handleCloseModal('subscriptions')}
        center
        classNames={{
          modal: 'custom-modal',
          overlay: 'custom-overlay',
        }}
      >
        <div>
          <h2 className="toolbar-header gradient-toolbar px-4 py-2">Subscriptions</h2>
          <p className="doc-sub-header">View and manage your subscriptions here.</p>
          <button
            className="cancel-btn mx-2"
            onClick={() =>
              handleClearCreditCard(document.getElementById("deleteCardPassword").value)
            }
          >
            Update Plan
          </button>
        </div>
      </Modal>
  
      {/* Payments Modal */}
      <Modal
        open={openModal.payments}
        onClose={() => handleCloseModal('payments')}
        center
        classNames={{
          modal: 'custom-modal',
          overlay: 'custom-overlay',
        }}
      >
        <div>
          <h2 className="toolbar-header gradient-toolbar px-4 py-2">Payments</h2>
          <p className="doc-sub-header">Current Payment Method</p>
          <div className="mb-4">
            {cards.map((card, index) => (
              <div key={index} className="d-flex justify-content-between align-items-center mb-2">
                <div className="doc-body-large">
                  {card.brand} ending in ....{card.last4}
                </div>
                {/* <div>
                  <button
                    className="profile-pref-btn mr-2"
                    onClick={() => handleClearCreditCard('password', card.last4)}
                  >
                    Delete
                  </button>
                  <button
                    className="profile-pref-btn"
                    onClick={() => selectCard(card.last4)}
                  >
                    Select
                  </button>
                </div> */}
              </div>
            ))}
          </div>
          {/* Card Details */}
          <div className="mb-3">
            <input className="my-3 input-field-card-number" type="text" placeholder="Card Number" id="cardNumber" />
          </div>
          <div className="d-flex mb-3">
            <input className="mr-2 input-field-asset-name" type="text" placeholder="Expiration" id="expiration" />
            <input className="ml-2 input-field-asset-name" type="text" placeholder="CVC" id="cvc" />
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="confirm-btn px-5"
              onClick={() =>
                handleSendStripeData(
                  document.getElementById("stripeToken").value,
                  document.getElementById("cardNumber").value,
                  document.getElementById("expiration").value,
                  document.getElementById("cvc").value,
                )
              }
            >
              Add Card
            </button>
          </div>
        </div>
      </Modal>
  
      {/* Billing Modal */}
      <Modal
        open={openModal.billing}
        onClose={() => handleCloseModal('billing')}
        center
        classNames={{
          modal: 'custom-modal',
          overlay: 'custom-overlay',
        }}
      >
        <div>
        <div className="d-flex justify-content-center mb-4">
          <button
            className={`billing-tab render-btn ${billingTab === 'info' ? 'active-tab' : ''}`}
            onClick={() => setBillingTab('info')}
          >
            Billing Info
          </button>
          <button
            className={`billing-tab render-btn ${billingTab === 'history' ? 'active-tab' : ''}`}
            onClick={() => setBillingTab('history')}
          >
            Payment History
          </button>
        </div>
          {billingTab === 'info' && (
            <div>
              <h2 className="toolbar-header gradient-toolbar px-4 py-2">Billing Information</h2>
              <p className="doc-sub-header">User's general billing information displayed here.</p>
            </div>
          )}
          {billingTab === 'history' && (
            <div>
              <h2 className="toolbar-header gradient-toolbar px-4 py-2">Payment History</h2>
              <table className="table table-dark table-striped">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Payment Method</th>
                  </tr>
                </thead>
                {/* <tbody>
                  <tr>
                    <td>01/01/2024</td>
                    <td>$99.99</td>
                    <td>Visa ending in 1234</td>
                  </tr>
                  <tr>
                    <td>02/01/2024</td>
                    <td>$99.99</td>
                    <td>Mastercard ending in 5678</td>
                  </tr>
                </tbody> */}
              </table>
            </div>
          )}
        </div>
      </Modal>
  
      {/* Delete Account Modal */}
      <Modal
        open={openModal.deleteAccount}
        onClose={() => handleCloseModal('deleteAccount')}
        center
        classNames={{
          modal: 'custom-modal',
          overlay: 'custom-overlay',
        }}
      >
        <div className="d-flex flex-column">
          <h2 className="profile-toolbar-header gradient-toolbar d-flex align-items-center px-2">Delete Account</h2>
          <p className="doc-body-large mt-0 mb-2">Are you sure you want to delete your account? This action cannot be undone.</p>
          <div className="d-flex flex-row">
            <input className="my-3 input-field-asset-name" type="password" placeholder="Enter your password" id="deleteAccountPassword" />
            <button
              className="confirm-btn ml-3"
              onClick={() =>
                handleDeleteAccount(document.getElementById("deleteAccountPassword").value)
              }
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
  
  

}

export default Profile;
