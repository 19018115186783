import React, { useEffect } from 'react';
import Prism from 'prismjs';


const CodeSnippet = ({ code, language }) => {
    useEffect(() => {
      Prism.highlightAll();
    }, []);
  
    return (
      <pre className="">
        <code className={`language-${language}`}>{code}</code>
      </pre>
    );
  };

export default CodeSnippet;
