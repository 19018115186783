import React, { useEffect, useState } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./TemplateSelector.css"; 
import { apiAddAsset } from './ApiClient';
import { useNavigate } from "react-router-dom";
import { phpSessAssetCountSet, phpSessAssetCountGet } from './PhpSession.js';
import Circle from "./images/circle-btn.svg";
import Arrow from "./images/arrow-btn.svg";
import Bar from "./images/bar-btn.svg";
import toast from 'react-hot-toast';

function TemplateSelector ({ forceRedrawCallback, closeModal })
{
  const [expandedTier, setExpandedTier] = useState(1);
  const [assetName, setAssetName] = useState("");
  const [activeButton, setActiveButton] = useState(null);
  const [selectedComponentId, setSelectedComponentId] = useState(0);
  const navigate = useNavigate();
  
  const tier1Images = [{img: Circle, cid: 1, hid: "t1-1"}, {img: Arrow, cid: 5, hid: "t1-5"}, {img: Bar, cid: 3, hid: "t1-3"}];

  useEffect(() => {
    //
  }, []);

  function openEditor (component_name, asset_id) 
  {
    var editorName = "";

    switch (component_name) {
      case "seg_bar16":
      case "seg_bar32":
      case "seg_bar64":
        editorName = "bar";
        break;
      case "arrow1":
        editorName = "arrow";
        break;
      case "wedges1":
        editorName = "radialbasic";
        break;

      default:
        return;
    }

    navigate (`/editor/${editorName}/${asset_id}`);
  };

  const handleAddAsset = (event) => {
    setAssetName(event.target.value);
  };

  function handleAssetTypeButtonClick (buttonId)
  {
    setActiveButton (buttonId);

    switch (buttonId) {
      case "circle":
        setSelectedComponentId(1);
        return;
      case "bar":
        setSelectedComponentId(3);
        return;
      case "arrow":
        setSelectedComponentId(5);
        return;

      default:
        return;
    }
  };

  const handleButtonIdClick = (buttonId, componentId) => {
    setActiveButton(buttonId);
    setSelectedComponentId(componentId);
  };

  function handleButtonAddAssetClick ()
  {
    if (selectedComponentId === 0) {
      toast.error('Please select a Component Type.');
      return;
    }

    const post_data = { asset_name: assetName, component_id: selectedComponentId };

    apiAddAsset(post_data, function (data) {
      if (data  &&  data.id  &&  data.component_name) {
        //setConceptState(conceptState + 1);
        toast.success ('New Asset Added Successfully!');
        closeModal ();
        const cnt = parseInt (phpSessAssetCountGet ()) + 1;
        phpSessAssetCountSet (cnt);
        openEditor (data.component_name, data.id);
        forceRedrawCallback ();
      } 
      else {
        toast.error('Invalid Response While Attempting to Add Asset.');
      }
    });
  };

  const toggleExpand = (tier) => {
    setExpandedTier(expandedTier === tier ? null : tier);
  };

  const tiers = [
    {
      label: "Free Version",
      size: "small",
      count: 3,
      tier: 1,
      visible: "rectangle-parent",
    },
    {
      label: "Tier 1",
      size: "small",
      count: 3,
      tier: 2,
      visible: "rectangle-parent",
    },
    // {
    //   label: "Tier 2",
    //   size: "large",
    //   count: 20,
    //   tier: 3,
    //   visible: "rectangle-parent",
    // },
  ];

  return (
  <div className="main-container">
    <div className="input-wrapper-add" style={{ display: 'flex', alignItems: 'center' }}>
      <label 
        htmlFor="asset-name" 
        className="form-label input-label-text"
        style={{ display: 'flex', alignItems: 'center', marginRight: '10px', minWidth: '100px', textAlign: 'left', whiteSpace: 'nowrap' }}

      >
        Asset Name
      </label>
      <input
        type="text"
        id="asset-name"
        name="asset-name"
        className="input-field-asset-name"
        value={assetName}
        onChange={handleAddAsset}
        placeholder="my-new-asset"
        style={{ flex: 1, maxWidth: '200px' }}
      />
    </div>
    <div className="frame-parent">
      {tiers.map((tier, index) => (
        <div key={index} className={tier.visible}>
          <div className="label-wrapper" onClick={() => toggleExpand(tier.tier)}>
            <div className="label">{tier.label}</div>
          </div>
          <div
            className={`group-wrapper ${
              expandedTier === tier.tier ? "expanded" : "collapsed"
            }`}
          >
            <div className="rectangle-group">
              {tier.label === "Free Version" ? (
                <div className="col-12 d-flex justify-content-center align-items-center mb-4">
                  <button
                    className={`new-asset-btn ${
                      activeButton === "circle" ? "active" : ""
                    }`}
                    id="circle"
                    onClick={() => handleAssetTypeButtonClick("circle")}
                  >
                    <img src={Circle} alt="Circle" />
                  </button>
                  <button
                    className={`new-asset-btn ${
                      activeButton === "bar" ? "active" : ""
                    }`}
                    id="bar"
                    onClick={() => handleAssetTypeButtonClick("bar")}
                  >
                    <img src={Bar} alt="Bar" />
                  </button>
                  <button
                    className={`new-asset-btn ${
                      activeButton === "arrow" ? "active" : ""
                    }`}
                    id="arrow"
                    onClick={() => handleAssetTypeButtonClick("arrow")}
                  >
                    <img src={Arrow} alt="Arrow" />
                  </button>
                </div>
              ) : (
                tier1Images.map((item, i) => (
                
                  <button
                    key={i}
                    className={`new-asset-btn ${
                      activeButton === item.hid ? "active" : ""
                    }`}
                    id={item.hid}
                    onClick={() => handleButtonIdClick(item.hid, item.cid)}
                  >
                    <img src={item.img} alt={`Free-${i}`} />
                  </button>
                ))
              )}
            </div>

          </div>
        </div>
      ))}
      <div className="group-frame d-flex justify-content-center flex-row">
        <div className="">
          
          <button className="cancel-btn" onClick={closeModal}>
            Cancel
          </button>
        </div>
        <div className="">
          
          <button className="create-btn" onClick={handleButtonAddAssetClick}>
            +Create
          </button>
        </div>
      </div>
    </div>
  </div>
);

};

export default TemplateSelector;
