import "../App.css";
import React, { useState, useEffect, useRef } from 'react';
import { Popover } from 'react-tiny-popover';
import { formatStyles, formatClasses } from "./EditorUtils";
import ColorPicker from './ColorPicker';

//      Required to use this component:
//        npm install react-tiny-popover --save


function ColorPopover ( { initialColor, inUseColors, cNames="square-color-picker", styles=null, saveChanges }) 
{
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const latestColor = useRef(initialColor);

  styles = formatStyles  (styles);
  cNames = formatClasses (cNames);

  styles ={...styles, backgroundColor: initialColor};

  function handleOutsideClick (e)
  {
    finished (latestColor.current);
  }

  function handleColorSelected (newColor)
  {
    latestColor.current = newColor;
  }

  function finished (hexColor)
  {
    if (hexColor !== 'cancel'  &&  hexColor !== initialColor) {
      saveChanges (hexColor);
    }
    setIsPopoverOpen (false);
  }

  function handleKeyDown (event)
  {
    if (event.key === 'Escape') {
      setIsPopoverOpen (false);
    }
    if (event.key === 'Enter') {
      finished (latestColor.current);
    }
  }


  function showContent ()
  {
    return (
      <div className="color-picker-popover">
        <ColorPicker  
          initialHex={initialColor}  
          inUseColors={inUseColors}
          onColorChange={handleColorSelected}
          finished={finished}
        />
      </div>
    );
  }
  

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });


   return (
     <div>
       <Popover
         isOpen={isPopoverOpen}
         align={'start'}
         positions={['right', 'bottom', 'top', 'left']} // preferred positions
         padding={0} // space between the target and popover content
         content= { showContent () }
         onClickOutside={(e) => handleOutsideClick(e)}
       >
         <div
            className={cNames}
//          style={{ backgroundColor: initialColor }}
            style={styles}
            onClick={(e) => setIsPopoverOpen(true)}
         />

       </Popover>
     </div>
   );
}

export default ColorPopover;
