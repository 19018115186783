import React from "react";
import Sidebar from "../Sidebar";
import "../../../App.css";
import CodeSnippet from "../../Prism";
import BrowserSetup from "../../images/tutorial-browser-setup.png";
import ConsoleSetup from "../../images/django-directory.png";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-jsx";

import AssetBuilder from "../../images/asset-builder.png";
import AssetDashboard from "../../images/asset-dashboard.png";
import DjangoDirectory from "../../images/django-directory.png";
import ReactDirectory from "../../images/react-directory.png";
import FinalRender from "../../images/final-render.png";
import DividerLine from "../../images/divider-line.svg";

const FirstAsset = () => {


  return (
    <div className="bg-main text-white mt-5">
      <div className="row">
        <Sidebar />
        <div className="col doc-container">
          <div className="col mt-4">
            <h2 className="doc-caps">MD99 DGE - v0.2</h2>
            <h1 className="doc-header">Design Your First Asset</h1>
            <h2 className="doc-caps">Easy, Fun & Efficient</h2>
          </div>

          <div className="px-5">

{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            
            <div className="snippet-container mt-5">
              <h3 className="doc-sub-header">Creating and Testing a Graphic</h3>

              <h1 className="doc-body-large">1) Create an Account, Login, and Access My Assets:</h1>
              <ul className="footer-bg py-4">
                <li className="doc-body-medium p-1">Create an account.</li>
                <li className="doc-body-medium p-1">Login to the myDesign99 web application.</li>
                <li className="doc-body-medium p-1">Upon landing on the "My Assets" dashboard, click the "+New Asset" button.</li>
              </ul>

              <h1 className="doc-body-large">2) Create a New Asset:</h1>
              <ul className="footer-bg py-4">
                <li className="doc-body-medium p-1">Give your asset a name</li>
                <li className="doc-body-medium p-1">Select an asset type: Radial, Bar, or Arrow.</li>
                <li className="doc-body-medium p-1">You will then be directed to the asset builder.</li>
              </ul>

              <h1 className="doc-body-large">3) Build Your Asset:</h1>
              <ul className="footer-bg py-4">
                <li className="doc-body-medium p-1">Use our intuitive editor to design your custom graphic (we call these "assets").</li>
                <li className="doc-body-medium p-1">Login to the myDesign99 web application.</li>
              </ul>

              <h1 className="doc-body-large">4) Navigate Back to My Assets Dashboard:</h1>
              <ul className="footer-bg py-4">
                <li className="doc-body-medium p-1">Once your asset is built, return to the "My Assets" dashboard.</li>
              </ul>

              <h1 className="doc-body-large">5) Generate Image URL:</h1>
              <ul className="footer-bg py-4">
                <li className="doc-body-medium p-1">Click on the "BUILD URL" button.</li>
                <li className="doc-body-medium p-1">Input the value you want to display in the graphic..</li>
                <li className="doc-body-medium p-1">Click the "Build" button to create a unique URL for that specific value and graphic.</li>
              </ul>

              <h1 className="doc-body-large">6) Test in Browser:</h1>
              <ul className="footer-bg py-4">
                <li className="doc-body-medium p-1">Copy the constructed image URL.</li>
                <li className="doc-body-medium p-1">Paste the URL into a new browser window to see your live asset delivered from our server.</li>
              </ul>

            </div>

            <img
              src={DividerLine}
              alt="Browser setup"
              style={{ width: "100%", height: "auto" }}
              className="mt-5"
            />

{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            
            <div className="snippet-container mt-5">
              <h4 className="doc-header">Advanced Usage: Dynamic Route Construction</h4>
              <p className="doc-body-small">
              For more advanced usage, you can refer to our dynamic route construction tutorial. This guide will help you review our Django/React route construction templates, giving you more control over interacting with the myDesign99 API. If you would like to utilize the MD99 API with your Node.js or PHP server code, send us a message requesting access to this library, and we will provide you with direct links.
              </p>

              <h3 className="doc-sub-header">Implementing Assets in Larger Data Sets</h3>

              <h1 className="doc-body-large">1) Review the Tutorial:</h1>
              <ul className="footer-bg py-4">
                <li className="doc-body-medium p-1">Follow the steps in the dynamic route construction tutorial available on our website.</li>
              </ul>

              <h1 className="doc-body-large">2) Integrate with Your Backend:</h1>
              <ul className="footer-bg py-4">
                <li className="doc-body-medium p-1">Use the provided templates to integrate myDesign99 assets into your backend and database setup.</li>
                <li className="doc-body-medium p-1">This allows you to use single assets to render large data sets dynamically, making it easy to deliver results for individuals, teams, and organizations.</li>
              </ul>
            </div>

            <img
              src={DividerLine}
              alt="Browser setup"
              style={{ width: "100%", height: "auto" }}
              className="mt-5"
            />
         

{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <div className="snippet-container mt-5">
              <h4 className="doc-header">Summary</h4>
              <p className="doc-sub-header">We value your insights and suggestions, so please provide feedback on your experience. Your contributions will help us improve MyDesign99 for all users.</p>
              <p className="doc-sub-header">Thank you for participating in our testing program!</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstAsset;
