function FontFamily (props) {
    const allFonts = [
      {val: "bebasneue-regular",      txt: "Bebasneue Regular"},
      {val: "bungeehairline-regular", txt: "Bungee Hair Line Regular"},
      {val: "jetbrains-mono-regular", txt: "Jetbrains Mono Regular"},
      {val: "jetbrains-mono-bold",    txt: "Jetbrains Mono Bold"},
      {val: "lato-regular", txt: "Lato Regular"},
      {val: "lato-bold",    txt: "Lato Bold"},
      {val: "leaguespartan-regular", txt: "League Spartan Regular"},
      {val: "leaguespartan-bold",    txt: "League Spartan Bold"},
      {val: "lexend-regular", txt: "Lexend Regular"},
      {val: "lexend-bold",    txt: "Lexend Bold"},
      {val: "montserrat-regular", txt: "Montserrat Regular"},
      {val: "montserrat-bold",    txt: "Montserrat Bold"},
      {val: "robotocondensed-regular", txt: "Roboto-Condensed Regular"},
      {val: "robotocondensed-bold",    txt: "Roboto-Condensed Bold"}
    ];
  
    const optionsAsHtml = allFonts.map((oneFont, index) => <option key={props.pID + index} value={oneFont.val}>{oneFont.txt}</option>);
     
    return (
      <div className="col-12 d-flex justify-content-between align-items-center divider-line toolbar-hover py-2">
        <label htmlFor={props.pID} className="toolbar-text">{props.pLabel}</label>
        <select id={props.pID}  className="input-field-font" value={props.pValue} onChange={(e)=> props.pCallback(e.target.value)}>
           {optionsAsHtml}
        </select>
     </div>
    );
  }
  export default FontFamily;