import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "../App.css";
import DashboardManagerConcept from "./DashboardManagerConcept";
import { phpSessIsLoggedIn, phpSessGet } from './PhpSession.js';
import { apiAddAsset } from './ApiClient';
import DividerLine from "./images/divider-line.svg";
import Circle from "./images/circle-btn.svg";
import Arrow from "./images/arrow-btn.svg";
import Bar from "./images/bar-btn.svg";
import MyAssetsBlur from "./images/myassets-blur.png";

function DashboardManagerParent ({ forceRedrawCallback })
{
  const navigate = useNavigate();
  const [assetName, setAssetName] = useState("");
  const [selectedComponentId, setSelectedComponentId] = useState("");
  const [activeButton, setActiveButton] = useState(null);
  const [view, setView] = useState("Concept");
  const [addNewAsset, setAddNewAsset] = useState("");
  const [conceptState, setConceptState] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [sessionToken, setSessionToken] = useState("");
  const [showAuthModal, setShowAuthModal] = useState(false);

  useEffect(() => {
    const authenticated = phpSessIsLoggedIn();
    setIsAuthenticated(authenticated);
    setSessionToken(phpSessGet());
    if (!authenticated) {
      setShowAuthModal(true);
    }
  }, []);

  const handleAssetNameChange = (event) => {
    setAssetName(event.target.value);
  };

  const handleAddAsset = (event) => {
    setAddNewAsset(event.target.value);
  };

  const handleButtonAddAssetClick = () => {
    if (selectedComponentId === 0) {
      toast.error('Please select a Component Type.');
      return;
    }

    const post_data = {
      asset_name: assetName,
      component_id: selectedComponentId,
      lsi_session_id: sessionToken,
    };
    apiAddAsset(post_data, function(data) {
      if (data && data.id && data.component_name) {
        setConceptState(conceptState + 1);
        toast.success('New Asset Added Successfully!');
        openEditor(data.component_name, data.id);
      } else {
        toast.error('Invalid Response While Attempting to Add Asset.');
      }
    });
  };

  const openEditor = (component_name, asset_id) => {
    var editorName = "radialbasic";
    switch(component_name) {
      case "seg_bar16": 
      case "seg_bar32": 
      case "seg_bar64":
        editorName = "bar";
        break;
      case "arrow1":
        editorName = "arrow";
        break;
      case "wedges1":
        editorName = "radialbasic";
        break;
    }
    navigate(`/editor/${editorName}/${asset_id}`);
  };

  const handleAssetTypeButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    let compid = 0;
    if (buttonId === "circle") {
      compid = 1;
    } else if (buttonId === "bar") {
      compid = 3;
    } else if (buttonId === "arrow") {
      compid = 5;
    }
    setSelectedComponentId(compid);
  };

  const [open, setOpen] = useState(false);

  const handleBeginButtonClick = () => setOpen(true);

  const onCloseModal = () => setOpen(false);

  const handleAuthModalClose = () => setShowAuthModal(false);

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleSignUpClick = () => {
    navigate('/signup');
  };

  const getButtonClass = (buttonView) => {
    return view === buttonView ? "reset-btn p-2 m-1" : "render-btn mr-2 p-2 m-1";
  };

  // const handleCopyClick = () => {
  //   navigator.clipboard.writeText('c145f5ef-e5a9-4ef8-877b-58cdb9d83474');
  //   toast.success("✓ API KEY copied to clipboard!", {
  //     position: "top-center",
  //     duration: 5000,
  //     style: {
  //       background: '#4caf50',
  //       color: '#fff',
  //       fontSize: '16px',
  //     },
  //   });
  // };

  return (
    <div className="dashboard-manager container-fluid bg-main pt-3 mt-3 mb-5">
      <Toaster />
      <div className="d-flex justify-content-between align-items-center mb-2">
        <p className="dashboard-title">My Assets</p>
        {/* <button
          className="create-btn py-1 px-3"
          onClick={handleBeginButtonClick}
        >
          +NEW ASSET
        </button> */}
      </div>
      
      {!isAuthenticated && (
        <div className="div">
          <img
            src={MyAssetsBlur}
            className="myasset-blur-icon"
            alt="Login or Create an Account to View Your Assets"
          />
        </div>
      )}

      <Modal
        open={open}
        onClose={onCloseModal}
        className="login-container"
        center
        showCloseIcon={false}
        styles={{
          modal: {
            background: "rgb(25,25,34)",
            background: "radial-gradient(circle, rgba(25,25,34,1) 0%, rgba(21,21,28,1) 100%)",
            borderRadius: "10px",
          },
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center p-3">
          <div className="input-wrapper">
            <label htmlFor="asset-name" className="form-label input-label-text">
              Asset Name
            </label>
            <input
              type="text"
              id="asset-name"
              name="asset-name"
              className="input-field-asset-name p-3"
              value={assetName}
              onChange={handleAssetNameChange}
              placeholder="my-new-asset"
            />
          </div>
          <div className="login-inner d-flex justify-content-center">
            <img className="vector-icon" loading="lazy" alt="" src={DividerLine} />
          </div>
          <div className="welcome-wrapper">
            <h1 className="d-flex justify-content-center hero-body pt-3 py-1">
              Choose an Asset style
            </h1>
          </div>
          <div className="col-12 d-flex justify-content-center align-items-center mb-4">
            <button
              className={`new-asset-btn ${activeButton === "circle" ? "active" : ""}`}
              id="circle"
              onClick={() => handleAssetTypeButtonClick("circle")}
            >
              <img src={Circle} alt="Circle" />
            </button>
            <button
              className={`new-asset-btn ${activeButton === "bar" ? "active" : ""}`}
              id="bar"
              onClick={() => handleAssetTypeButtonClick("bar")}
            >
              <img src={Bar} alt="Bar" />
            </button>
            <button
              className={`new-asset-btn ${activeButton === "arrow" ? "active" : ""}`}
              id="arrow"
              onClick={() => handleAssetTypeButtonClick("arrow")}
            >
              <img src={Arrow} alt="Arrow" />
            </button>
          </div>
          <div className="group-frame d-flex justify-content-center flex-row">
            <div className="rectangle-parent">
              <div className="frame-inner" />
              <button className="cancel-btn" onClick={onCloseModal}>
                Cancel
              </button>
            </div>
            <div className="rectangle-group">
              <div className="rectangle-div" />
              <button className="create-btn" onClick={handleButtonAddAssetClick}>
                +Create
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={showAuthModal}
        onClose={handleAuthModalClose}
        center
        showCloseIcon={false}
        styles={{
          modal: {
            background: "rgb(25,25,34)",
            background: "radial-gradient(circle, rgba(25,25,34,1) 0%, rgba(21,21,28,1) 100%)",
            borderRadius: "10px",
          },
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center p-3">
          <h2 className="pricing-text">Login or Sign Up to start building and viewing your assets!</h2>
          <div className="d-flex justify-content-center mt-4">
            {/* <button className="create-btn px-4 py-2 mx-2" onClick={handleSignUpClick}>Sign Up</button>
            <button className="create-btn px-4 py-2 mx-2" onClick={handleLoginClick}>Login</button> */}
          </div>
        </div>
      </Modal>

      {isAuthenticated && (
        <span key={conceptState}>
          <DashboardManagerConcept name={"My Asset 1"} forceRedrawCallback={forceRedrawCallback} />
        </span>
      )}
    </div>
  );
};

export default DashboardManagerParent;
