import React from "react";
import Sidebar from "../Sidebar";
import "../../../App.css";
import CodeSnippet from "../../Prism";
import BrowserSetup from "../../images/tutorial-browser-setup.png";
import ConsoleSetup from "../../images/tutorial-concept-setup.png";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-jsx";

const ErrorHandling = () => {

  return (
    <div className="bg-main text-white mt-5">
      <div className="row">
        <Sidebar />
        <div className="col doc-container">
          <div className="col mt-4">
            <h2 className="doc-caps">How to use the MD99 API</h2>
            <h1 className="doc-header">Error Handling</h1>
            <h3 className="doc-sub-header">
            Coming Soon.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorHandling;
