import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { motion } from "framer-motion";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css"; // Import the styles for the modal
import Checkout from "./Checkout"; // Import the Checkout component
import CheckMark from "./images/check-mark.svg";
import DividerLine from './images/divider-line.svg'; // Adjust the path as necessary

function Pricing() {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <motion.div
      className="box"
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 1.5,
        ease: "easeOut",
        scale: {
          type: "spring",
          damping: 10,
          stiffness: 50,
          restDelta: 0.001
        }
      }}
    >
      <div className="container mt-5">
        <div className="row pricing-row">
          <div className="col-12 d-flex justify-content-center flex-column">
            <h1 className="subheader-text d-flex justify-content-center">
              Pricing
            </h1>
            {/* <p className="pricing-text mb-5 mt-2 justify-content-center">
              Welcome to our Pricing page! Explore our flexible subscription plans tailored for every need. Each tier offers unique access levels to our cutting-edge features, ensuring you find the perfect match for your needs.
            </p> */}
          </div>
        </div>
        <div className="row justify-content-center">
          {/* Free Access Section */}
          <div className="col-3 pricing-box component-title mr-2 border border-light">
          <div>
            <h2 className="pricing-title d-flex justify-content-center">Free Version</h2>
            <img
              src={DividerLine}
              alt="Browser setup"
              style={{ width: "100%", height: "auto" }}
              className="mb-2"
            />
            <p className="d-flex justify-content-center subheader-text-2">$0</p>
            
            <ul className="no-bullets d-flex flex-column justify-content-start">
              <li>
                <img className="p-2" src={CheckMark} alt="Check mark" /> 1000 Usages Monthly
              </li>
              <li>
                <img className="p-2" src={CheckMark} alt="Check mark" /> $0.0025 per usage over 1000
              </li>
            </ul>
            <div className="d-flex justify-content-center">
              <button className="render-btn px-4 py-3">Current</button>
            </div>
          </div>
        </div>


          {/* Basic Access Section */}
          <div className="col-3 pricing-box component-title mr-2 d-flex justify-content-center">
            <div className="justify-content">
              <h2 className="pricing-title d-flex justify-content-center">Essentials</h2>
              <img
                src={DividerLine}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mb-2"
              />
              <p className="d-flex justify-content-center subheader-text-2">$9.95</p>
              
              <ul className="no-bullets d-flex flex-column">
                <li>
                  <img className="p-2 " src={CheckMark} alt="Check mark" /> 4000 Usages Monthly
                </li>
                <li>
                  <img className="p-2" src={CheckMark} alt="Check mark" /> $0.0025 per usage over 4000
                </li>
                {/* <li>
                  <img className="p-2" src={CheckMark} alt="Check mark" /> 500 Free Edits
                </li>
                <li>
                  <img className="p-2" src={CheckMark} alt="Check mark" /> 300 Free Image Retrievals
                </li> */}
              </ul>
              <div className="d-flex justify-content-center">
                <button className="create-btn" onClick={handleShow}>
                  Subscribe
                </button>
              </div>
            </div>
          </div>

          {/* Full Access Section */}
          <div className="col-3 pricing-box component-title d-flex justify-content-center">
            <div>
              <h2 className="pricing-title d-flex justify-content-center">Professional</h2>
              <img
                src={DividerLine}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mb-2"
              />
              <p className="d-flex justify-content-center subheader-text-2">$23.95</p>
              
              <ul className="no-bullets d-flex justify-content-center flex-column">
                <li>
                  <img className="p-2" src={CheckMark} alt="Check mark" /> 12,000 Usages Monthly
                </li>
                <li>
                  <img className="p-2" src={CheckMark} alt="Check mark" /> $0.0018 per usage over 12,000
                </li>
                {/* <li>
                  <img className="p-2" src={CheckMark} alt="Check mark" /> 500 Free Edits
                </li>
                <li>
                  <img className="p-2" src={CheckMark} alt="Check mark" /> 300 Free Image Retrievals
                </li> */}
              </ul>
              <div className="d-flex justify-content-center">
                <button className="create-btn" onClick={handleShow}>
                  Subscribe
                </button>
              </div>
            </div>
          </div>

          <div className="col-3 pricing-box component-title mr-2 d-flex justify-content-center">
            <div>
              <h2 className="pricing-title d-flex justify-content-center">Team</h2>
              <img
                src={DividerLine}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mb-2"
              />
              <p className="d-flex justify-content-center subheader-text-2">$48.95</p>
              
              <ul className="no-bullets d-flex justify-content-start flex-column">
                <li>
                  <img className="p-2" src={CheckMark} alt="Check mark" /> 30,000 usages
                </li>
                <li>
                  <img className="p-2" src={CheckMark} alt="Check mark" /> $0.0014 per usage over 30,000

                </li>
                {/* <li>
                  <img className="p-2" src={CheckMark} alt="Check mark" /> 500 Free Edits
                </li>
                <li>
                  <img className="p-2" src={CheckMark} alt="Check mark" /> 300 Free Image Retrievals
                </li> */}
              </ul>
              <div className="d-flex justify-content-center">
                <button className="create-btn" onClick={handleShow}>
                  Subscribe
                </button>
              </div>
            </div>
          </div>

          <div className="col-3 pricing-box component-title mr-1 d-flex justify-content-center">
            <div>
              <h2 className="pricing-title d-flex justify-content-center">Enterprise</h2>
              <img
                src={DividerLine}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mb-2"
              />
              <p className="d-flex justify-content-center subheader-text-2">Custom</p>
              <p className="d-flex justify-content-center">Contact us</p>
              <ul className="no-bullets d-flex justify-content-start flex-column">
                <li>
                  <img className="p-2" src={CheckMark} alt="Check mark" /> Flexible Usage Options
                </li>
                {/* <li>
                  <img className="p-2" src={CheckMark} alt="Check mark" /> 500 Free Edits
                </li>
                <li>
                  <img className="p-2" src={CheckMark} alt="Check mark" /> 300 Free Image Retrievals
                </li> */}
              </ul>
              <div className="d-flex justify-content-center">
                <button className="create-btn" onClick={handleShow}>
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Structure */}
      <Modal
        open={showModal}
        onClose={handleClose}
        center
        classNames={{
          modal: "custom-modal",
          overlay: "custom-overlay",
        }}
      >
        <Checkout /> {/* Render the Checkout component inside the modal */}
      </Modal>
    </motion.div>
  );
}

export default Pricing;
