export function rgbToHex (r, g, b)
{
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
export function rgbObjToHex (rgbObj) 
{
	return rgbToHex (rgbObj.r, rgbObj.g, rgbObj.b)
}

export function hexToRgb (hex)
{
   if (hex === null  ||  hex.length === 0)
      return null;
	if (hex.charAt(0) === '#')
		hex = hex.substring(1);
	
	if (hex.length === 3)
		return formatComponents (hex.substr(0,1), hex.substr(1,1), hex.substr(2,1));

	if (hex.length === 6)
		return formatComponents (hex.substr(0,2), hex.substr(2,2), hex.substr(4,2));

	return null;
}

export function asRgbCss (r, g, b)
{
	r = parseInt (r);
	g = parseInt (g);
	b = parseInt (b);
	return "rgb("+r+","+g+","+b+")";
}
 
// -------------------------------------------------

function componentToHex (c) 
{
  c = parseInt (c);
  const hex = c.toString(16);
  return (hex.length === 1) ? ("0" + hex) : (hex);
}

function formatComponents (rTxt, gTxt, bTxt)
{
	const rInt = parseInt (rTxt, 16);
	const gInt = parseInt (gTxt, 16);
	const bInt = parseInt (bTxt, 16);
	
	if (isNaN(rInt)  ||  isNaN(gInt)  ||  isNaN(bInt))
		return null;
		
	return {r: rInt, g: gInt, b: bInt};
}

// -------------------------------------------------

export function fullySaturateHex (hex)
{
   var hslObj = hexToHsl (hex);
   return hslToHex (hslObj.h, 100, 50);
}

export function hueToHex (newHue, hex)
{
   var hslObj = hexToHsl (hex);
   return hslToHex (newHue, hslObj.s, hslObj.l)
}

export function hueToRgb (newHue, hex)
{
   var hslObj = hexToHsl (hex);
   return hslToRgb (newHue, hslObj.s, hslObj.l)
}

export function hslToHex (h, s, l)
{
   const rgbObj = hslToRgb (h, s, l);
   return rgbObjToHex (rgbObj);
}

export function hslToRgb (h, s, l)
{
   s /= 100;
   l /= 100;
   const k = (n) => (n + h / 30) % 12;
   const a = s * Math.min(l, 1 - l);

   const f = (n) => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));

   return {r: Math.round(f(0) * 255), g: Math.round(f(8) * 255), b: Math.round(f(4) * 255)};
}


export function hexToHue (hex)
{
   const hsl = hexToHsl (hex);
   return hsl.h;
}

export function hexToHsl (hex)
{
   const rgbObj = hexToRgb (hex);
   if (rgbObj == null) {
      return {h: 0, s: 0, l:0};
   }
   return rgbObjToHsl (rgbObj);
}


function rgbObjToHsl (rgbObj)
{
   return rgbToHsl (rgbObj.r, rgbObj.g, rgbObj.b);
}

function rgbToHsl (r, g, b)
{
   r /= 255;
   g /= 255;
   b /= 255;
   const max = Math.max(r, g, b), min = Math.min(r, g, b);
   let h, s, l = (max + min) / 2;

   if (max === min) {
     h = s = 0; // achromatic
   } else {
     const d = max - min;
     s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
     switch (max) {
       case r: h = (g - b) / d + (g < b ? 6 : 0); break;
       case g: h = (b - r) / d + 2; break;
       case b: h = (r - g) / d + 4; break;
       default: break;
     }
     h /= 6;
   }
   return {h: h*360, s: s*100, l:l*100};
}

// -------------------------------------------------

export function drawCrossHairs (x, y, canvasRef)
{
   const rad = 3;

   var ctx = canvasRef.current.getContext("2d");

   const imgData = ctx.getImageData (x, y, 1, 1);
   const hslObj  = rgbToHsl (imgData.data[0], imgData.data[1], imgData.data[2]);

   var lineColor = '#111111';
   if (hslObj.l < 50) {
      lineColor = '#ffffff';
   }

   /*
   ctx.beginPath();
   ctx.moveTo(x-5, y);
   ctx.lineTo(x+5, y);
   ctx.strokeStyle = lineColor;
   ctx.stroke();   
   
   ctx.beginPath();
   ctx.moveTo(x, y-5);
   ctx.lineTo(x, y+5);
   ctx.strokeStyle = lineColor;
   ctx.stroke();   
   */

   // Draw the Path
   ctx.beginPath();
   ctx.arc(x, y, rad, 0, 2 * Math.PI, false);
   ctx.fillStyle = lineColor;
   ctx.fill();
// ctx.lineWidth = 1;
// ctx.strokeStyle = '#111188';
// ctx.stroke();
}

export function drawBigSwatch (width, height, curColor, canvasRef)
{
   const requestedRgb = hexToRgb (curColor);
   if (requestedRgb == null) {
      return;
   }

   const requestedR   = requestedRgb.r;
   const requestedG   = requestedRgb.g;
   const requestedB   = requestedRgb.b;

   //const canvas = canvasRef.current.getContext('2d');
   var context = canvasRef.current.getContext("2d");

   var rBeg = 255;
   var gBeg = 255;
   var bBeg = 255;
   var rEnd = requestedR;
   var gEnd = requestedG;
   var bEnd = requestedB;

   const rOffsetBeg = 255 / height;
   const gOffsetBeg = 255 / height;
   const bOffsetBeg = 255 / height;
   const rOffsetEnd = requestedR / height;
   const gOffsetEnd = requestedG / height;
   const bOffsetEnd = requestedB / height;
   for (var row = 0; row< height; row++) {
      const c1 = asRgbCss(rBeg,gBeg,bBeg);
      const c2 = asRgbCss(rEnd,gEnd,bEnd);

      const grad=context.createLinearGradient (0, row, width, row);
      grad.addColorStop (0, c1);
      grad.addColorStop (1, c2); 

      context.fillStyle = grad;
      context.fillRect (0, row, width, row);
      
      rBeg -= rOffsetBeg;
      gBeg -= gOffsetBeg;
      bBeg -= bOffsetBeg;
      rEnd -= rOffsetEnd;
      gEnd -= gOffsetEnd;
      bEnd -= bOffsetEnd;
   }
}

// this did not work well
export function calcCoordOnGradient (width, height, topRightColor, searchColor)
{
   const topRightRgb = hexToRgb (topRightColor);
   if (topRightRgb == null)
      return null;
   const topRightR = topRightRgb.r;
   const topRightG = topRightRgb.g;
   const topRightB = topRightRgb.b;

   const searchRgb = hexToRgb (searchColor);
   if (searchRgb == null)
      return null;
   const searchR = searchRgb.r;
   const searchG = searchRgb.g;
   const searchB = searchRgb.b;

   var rBeg = 255;
   var gBeg = 255;
   var bBeg = 255;
   var rEnd = topRightR;
   var gEnd = topRightG;
   var bEnd = topRightB;

   const rOffsetBeg = 255 / height;
   const gOffsetBeg = 255 / height;
   const bOffsetBeg = 255 / height;
   const rOffsetEnd = topRightR / height;
   const gOffsetEnd = topRightG / height;
   const bOffsetEnd = topRightB / height;
   for (var row = 0; row < height; row++) {
      if (   searchR <= rBeg  &&  searchR >= rEnd
          && searchG <= gBeg  &&  searchG >= gEnd
          && searchB <= bBeg  &&  searchB >= bEnd ) {    // the values go from 255 -> 0

         const xOffsetR = (rEnd - rBeg)/ width;
         const xOffsetG = (gEnd - gBeg)/ width;
         const xOffsetB = (bEnd - bBeg)/ width;
         var   xCurR = rBeg;
         var   xCurG = gBeg;
         var   xCurB = bBeg;

         var   minDiff = 10000;
         var   finalx  = 0;
         for (var col = 0; col < width; col++) {
            const diff = Math.abs(xCurR - searchR) + Math.abs(xCurG - searchG) + Math.abs(xCurB - searchB);
            if (diff < minDiff) {
               minDiff = diff;
               finalx = col;
            }
            xCurR += xOffsetR;
            xCurG += xOffsetG;
            xCurB += xOffsetB;
         }
         return {x: finalx, y: row};
      }
      
      rBeg -= rOffsetBeg;
      gBeg -= gOffsetBeg;
      bBeg -= bOffsetBeg;
      rEnd -= rOffsetEnd;
      gEnd -= gOffsetEnd;
      bEnd -= bOffsetEnd;
   }
   return null;
}
