import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
/*
import Google from "./images/Google.svg";
import Apple from "./images/Apple.svg";
import Github from "./images/Github.svg";
import Windows from "./images/Windows.svg";
import Facebook from "./images/Facebook.svg";
import DividerLine from "./images/divider-line.svg";
*/
import BackBtn from "./images/back-btn.svg";
import { apiSignUp } from './ApiClient';
import toast, { Toaster } from "react-hot-toast";
import { motion } from "framer-motion";
import {
  CForm,
  CFormInput,
  CButton,
  CInputGroup,
  CFormFeedback
} from '@coreui/react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

function SignUp({ navigateViaParent }) 
{
  const topLevelNavigation = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailValid, setEmailValid] = useState(null);
  const [passwordValid, setPasswordValid] = useState(false);
  const [confirmPasswordMatch, setConfirmPasswordMatch] = useState(false);
  const [validated, setValidated] = useState(false);
  const [expand, setExpand] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isValidLength = password.length >= 8;
    return hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChar && isValidLength;
  };

  const validationMessage = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isValidLength = password.length >= 8;
    let fullMsg = "The password must contain ";
    let andStr  = "";
    if (!isValidLength) {
      fullMsg += andStr + "at least 8 characters";
      andStr = " and ";
    }
    if (!hasUpperCase) {
      fullMsg += andStr + "1 uppercase letter";
      andStr = " and ";
    }
    if (!hasLowerCase) {
      fullMsg += andStr + "1 lowercase letter";
      andStr = " and ";
    }
    if (!hasNumbers) {
      fullMsg += andStr + "1 number";
      andStr = " and ";
    }
    if (!hasSpecialChar) {
      fullMsg += andStr + "1 special character";
      andStr = " and ";
    }
    toast.error(fullMsg);
  };

  const handleEmailInputChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setEmailValid(validateEmail(value));
  };

  const handlePasswordInputChange = (event) => {
    const value = event.target.value;
    const isValid = validatePassword(value);
    setPassword(value);
    setPasswordValid(isValid);
    setConfirmPasswordMatch(value === confirmPassword);
  };

  const handleConfirmPasswordInputChange = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    setConfirmPasswordMatch(value === password);
  };

  const handleFirstNameInputChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameInputChange = (event) => {
    setLastName(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setTermsAccepted(event.target.checked);
  };


  const handleButtonSignUpClick = (event) => {
    event.preventDefault();
    setValidated(true);
    setExpand(true);  // Trigger the expansion

    if (!emailValid) {
      toast.error('Please enter a valid email.');
      return;
    }
    if (!passwordValid) {
      validationMessage(password);
      return;
    }
    if (!confirmPasswordMatch) {
      toast.error("Your passwords do not match.");
      return;
    }
    if (!termsAccepted) {
      toast.error("Please review the User Testing Agreement by following the link provided, then check the box to indicate that you agree with the Terms and Conditions.");
      return;
    }

    const post_data = {
      first_name: firstName,
      last_name: lastName,
      email_address: email,
      pwd: password,
      confirm: confirmPassword, 
    };
  
    apiSignUp(post_data, function(data) {
      if (data && data.lsi_session_id) {
        toast ('New Account Created! Welcome to MyDesign99! Enter your email and password to Login into your new account and begin building! Login to begin building!', {
          duration: 4000,
          position: 'center',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#713200',
            background: '#fff',
          },
          iconTheme: {
            primary: '#713200',
            secondary: '#FFFAEE',
          },
        });
        topLevelNavigation ("/dashboard"); // Redirect to dashboard on successful sign up
      } else {
        toast.error('Failed to Create a New User.');
      }
    });
  };

  return (
    <motion.div
      className="box"
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: .5,
        ease: "easeOut",
        scale: {
          type: "spring",
          damping: 10,
          stiffness: 50,
          restDelta: 0.001
        }
      }}
    >
      <div className={`signup-container mt-4 expand-from-bottom ${expand ? 'expand' : ''}`}>
        <Toaster />
        <div className="welcome-wrapper d-flex flex-row align-items-center justify-content-center mb-2">
          <img
            className="back-arrow-btn clickable"
            loading="lazy"
            alt="back-btn"
            src={BackBtn}
            onClick={() => navigateViaParent("Login")}
          />
          <h1 className="d-flex justify-content-center pricing-title">
            Create Account
          </h1>
        </div>

        <CForm 
          className="row g-3 needs-validation frame-parent mb-1 small-validation-text" 
          noValidate 
          validated={validated} 
          onSubmit={handleButtonSignUpClick}
        >
          <div className="frame-group">
            <div className="row">
              <div className="col-md-6 mb-2">
                <label
                  htmlFor="firstNameInput"
                  className="form-label input-label-text"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstNameInput"
                  name="firstName"
                  className="input-field-login p-3 m-0"
                  value={firstName}
                  onChange={handleFirstNameInputChange}
                  placeholder="Enter your first name"
                />
              </div>
              <div className="col-md-6 mb-2">
                <label
                  htmlFor="lastNameInput"
                  className="form-label input-label-text"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastNameInput"
                  name="lastName"
                  className="input-field-login p-3 m-0"
                  value={lastName}
                  onChange={handleLastNameInputChange}
                  placeholder="Enter your last name"
                />
              </div>
            </div>
            <div className="input-wrapper">
              <label
                htmlFor="emailInput"
                className="form-label input-label-text"
              >
                Email
              </label>
              <CFormInput
                type="email"
                id="emailInput"
                name="email"
                className={`input-field-login p-3  m-0${emailValid === true ? 'is-valid' : emailValid === false ? 'is-invalid' : ''}`}
                value={email}
                onChange={handleEmailInputChange}
                required
              />
              <CFormFeedback valid>Looks good!</CFormFeedback>
              <CFormFeedback invalid>Please provide a valid email.</CFormFeedback>
            </div>
            <div className="input-wrapper">
              <label
                htmlFor="passwordInput"
                className="form-label input-label-text"
              >
                Create a Password
              </label>
              <CInputGroup className="has-validation">
                <CFormInput
                  type="password"
                  id="passwordInput"
                  name="password"
                  className={`input-field-login p-3  m-0${passwordValid === true ? 'is-valid' : passwordValid === false ? 'is-invalid' : ''}`}
                  value={password}
                  onChange={handlePasswordInputChange}
                  required
                />
                <CFormFeedback valid>Looks good!</CFormFeedback>
                <CFormFeedback invalid>
                  Password must be at least 8 characters long, include at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number.
                </CFormFeedback>
              </CInputGroup>
            </div>
            <div className="input-wrapper">
              <label
                htmlFor="confirmPasswordInput"
                className="form-label input-label-text"
              >
                Confirm Password
              </label>
              <CInputGroup className="has-validation">
                <CFormInput
                  type="password"
                  id="confirmPasswordInput"
                  name="confirmPassword"
                  className={`input-field-login p-3  m-0${confirmPasswordMatch === true ? 'is-valid' : confirmPasswordMatch === false ? 'is-invalid' : ''}`}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordInputChange}
                  required
                />
                <CFormFeedback valid>Passwords match!</CFormFeedback>
                <CFormFeedback invalid>
                  Passwords must match and fulfill the password criteria.
                </CFormFeedback>
              </CInputGroup>
            </div>
          </div>
          <div className="form-check mt-3">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="termsCheck"
              checked={termsAccepted}
              onChange={handleCheckboxChange}
              required
            />
            <label className="form-check-label doc-body-medium" htmlFor="termsCheck">
              I confirm that I have read and accept the User Testing Agreement Terms and Conditions
            </label>
            <div>
              <a onClick={() => setModalOpen(true)} style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}>User Testing Agreement</a>
            </div>
          </div>
          <div className="group-frame d-flex justify-content-center flex-row">
            <div className="rectangle-parent">
              <div className="frame-inner" />
              <button className="sign-up render-btn px-5 py-2" onClick={() => navigateViaParent("Login")}>Cancel</button>
            </div>
            <div className="rectangle-group">
              <div className="rectangle-div" />
              <CButton className="login1 confirm-btn px-5 py-2" type="submit">Create</CButton>
            </div>
          </div>
        </CForm>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)} center>
          <div className="p-5">
            <h2>Test User Agreement Form</h2>
            <p>Test User Agreement</p>
            <p>This Test User Agreement ("Agreement") is entered into by and between the undersigned
            party ("Test User") and myDesign99. By participating in the testing of mydesign99.com
            ("Application"), the Test User agrees to the following terms and conditions:</p>
            <p>1. Purpose: The Test User agrees to test the Application and provide feedback to the
            Company. The feedback will include reporting any bugs, issues, or suggestions for
            improvement.</p>
            <p>2. Confidentiality: The Test User agrees to use My Design 99 products only as a
            consumer of the Product, and to not use any technology from the Product for their own
            personal gain, without written expressed permission or proper subscription permissions.</p>
            <p>3. Intellectual Property: The Company retains all rights, title, and interest in and to the
            Application, including any updates, modifications, or improvements made to the
            Application. The Test User does not acquire any rights or licenses to the Application by
            testing it.</p>
            <p>4. No Warranty: The Application is provided "as is" without any warranties, express or
            implied. The Company disclaims all warranties, including but not limited to, the implied
            warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
            <p>5. Limitation of Liability: In no event shall the Company be liable for any damages,
            including but not limited to, direct, indirect, incidental, special, or consequential
            damages, arising out of or in connection with the use or inability to use the Application.</p>
            <p>6. Term and Termination: This Agreement shall commence on the date of acceptance and
            continue until terminated by either party. The Company may terminate this Agreement at
            any time without notice.</p>
            <p>7. General Provisions:
            <ul>
              <li>This Agreement constitutes the entire agreement between the parties and
              supersedes all prior agreements and understandings, whether written or oral,
              relating to the subject matter hereof.</li>
              <li>This Agreement shall be governed by and construed in accordance with the laws
              of the State of Kentucky, USA, without regard to its conflict of laws principles.</li>
              <li>Any disputes arising under this Agreement shall be resolved exclusively in the
              state and federal courts located in USA, Kentucky.</li>
            </ul>
            </p>
          </div>
        </Modal>
      </div>
    </motion.div>
  );
}

export default SignUp;
