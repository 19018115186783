import React, { useRef } from 'react';
import { formatClasses } from "../EditUtils/EditorUtils";


function DroppableDiv ( { content, allClasses, activeClass, inactiveClass, callback })
{
   const dragArea = useRef(null);


   allClasses  = formatClasses (allClasses);
   allClasses += " " + inactiveClass;


   const dragEnterHandler = () => {
      dragArea.current.classList.add (activeClass);
      dragArea.current.classList.remove (inactiveClass);
   };
 
   const dragLeaveHandler = () => {
      dragArea.current.classList.add (inactiveClass);
      dragArea.current.classList.remove (activeClass);
   };
 
   const dragOverHandler = (e) => {
      e.preventDefault();
   };
 
   const dropHandler = (event) => {
      event.preventDefault();
 
      dragArea.current.classList.add (inactiveClass);
      dragArea.current.classList.remove (activeClass);
 
      const fileObj = findDroppedFile (event);
      callback (fileObj);
   };
 

  const findDroppedFile = (event) => {
   if (event.dataTransfer.items) {
      for (var i1 = 0; i1 < event.dataTransfer.items.length; i1++) {
         const item = event.dataTransfer.items[i1];
         if (item.kind === "file") {
            return item.getAsFile();
         }
      }
   }
   else {
      if (event.dataTransfer.files.length > 0) {
         return event.dataTransfer.files[0];
      }
   }
   return null;
   };


   return (
      <div
         ref={dragArea}
         className={allClasses}  
         onDragEnter={dragEnterHandler}
         onDragLeave={dragLeaveHandler}
         onDragOver={dragOverHandler}
         onDrop={dropHandler}
      >
         {content}
      </div>
   );
}


export default DroppableDiv;
